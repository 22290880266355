import { CreateChargingAgreementForm } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import { chargingBillingCycleOptions, ChargingServiceOption } from "components/ChargingAgreements/models";
import { ChargingPricing } from "components/ChargingPricings/models";
import { onNumberChange } from "helpers";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { ChargingServiceOptionService } from "services/charging/charging-service-option.service";

interface NewChargingAgreementServiceFormProps {
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
    pricing?: ChargingPricing;
}

export default function NewChargingAgreementServiceForm(
    { form, setForm, pricing } : NewChargingAgreementServiceFormProps
) {

    const [serviceOptions, setServiceOptions] = useState<ChargingServiceOption[]>([]);

    useEffect(() => {
        findPurchaseOptions();
    }, []);

    const findPurchaseOptions = async () => {
        try{
            const res = await ChargingServiceOptionService.findChargingServiceOptions();
            setServiceOptions(res)
        }catch(e){
            console.error(e);
        }
    }

    const options = serviceOptions.map(opt => ({ value: opt.id, label: opt.name }));
    const currentOption = options.find(opt => opt.value === form.serviceOptionId);
    const currentSimCardPaymentOption = chargingBillingCycleOptions.find(opt => opt.value === form.simCardPaymentType);
    const currentMaintenanceOption = chargingBillingCycleOptions.find(opt => opt.value === form.chargerMaintenanceType);
    
    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Laadimisteenus*</Form.Label>
                        <Select
                            required
                            options={options}
                            value={currentOption ?? null}
                            onChange={(opt: any) => {
                              setForm({
                                ...form,
                                serviceOptionId: opt.value
                              })
                            }}
                            placeholder='Vali teenus'
                            className={'w-100 form-control form-select' + (!form.serviceOptionId && form.validated ? ' is-invalid' : '')}
                        />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Label>Laadimisteenuse kuutasu (€)</Form.Label>
                    <Form.Control
                        value={form.chargingServiceMonthlyFeeEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            chargingServiceMonthlyFeeEuros: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Laadimisteenuse marginaal (s/kWh)</Form.Label>
                    <Form.Control
                        value={form.thirdPartyChargingMarginalCentsKwh}
                        onChange={(e: any) => setForm({
                            ...form,
                            thirdPartyChargingMarginalCentsKwh: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Seadme paigaldustasu (€)</Form.Label>
                    <Form.Control
                        value={form.installationFeeEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            installationFeeEuros: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Check
                        id='check-has-emergency-maintenance-service'
                        custom
                        type="checkbox"
                        className='checkbox-large'
                        label='Seadmete erakorraline hooldusteenus'
                        onChange={() => setForm({ 
                            ...form, 
                            hasEmergencyMaintenanceService: !form.hasEmergencyMaintenanceService
                        })}
                        checked={form.hasEmergencyMaintenanceService}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Check
                        id='check-has-charger-maintenance-service'
                        custom
                        type="checkbox"
                        className='checkbox-large'
                        label='Seadme hooldusteenuse olemasolu'
                        onChange={() => {
                            if(form.hasChargerMaintenanceService){
                                setForm({ 
                                    ...form, 
                                    hasChargerMaintenanceService: false,
                                    chargerMaintenanceType: undefined,
                                    maintenanceMonthlyFeeEuros: undefined
                                })
                            }else{
                                setForm({ 
                                    ...form, 
                                    hasChargerMaintenanceService: true
                                })
                            }
                        }}
                        checked={form.hasChargerMaintenanceService}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Label>Seadme hooldustasu makse sagedus</Form.Label>
                        <Select
                            options={chargingBillingCycleOptions}
                            value={currentMaintenanceOption ?? null}
                            onChange={(opt: any) => {
                                setForm({
                                    ...form,
                                    chargerMaintenanceType: opt?.value,
                                    maintenanceMonthlyFeeEuros: form.maintenanceMonthlyFeeEuros === undefined ? pricing?.maintenanceMonthlyFeeEuros : form.maintenanceMonthlyFeeEuros
                                })
                            }}
                            placeholder='Vali sagedus'
                            isClearable
                            className={'w-100 form-control form-select' + (form.validated && form.hasChargerMaintenanceService && !form.chargerMaintenanceType ? ' is-invalid' : '')}
                            isDisabled={!form.hasChargerMaintenanceService}
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Seadme hoolduse kuutasu (€)</Form.Label>
                    <Form.Control
                        value={form.maintenanceMonthlyFeeEuros ?? ''}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                maintenanceMonthlyFeeEuros: onNumberChange(e)
                            })
                        }}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                        disabled={!form.hasChargerMaintenanceService}
                        className={'w-100 form-control' + (form.validated && form.hasChargerMaintenanceService && form.maintenanceMonthlyFeeEuros === undefined ? ' is-invalid' : '')}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Label>Seadmete maksumus (€)</Form.Label>
                    <Form.Control
                        value={form.devicesCostEuros ?? ''}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                devicesCostEuros: onNumberChange(e)
                            })
                        }}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>SIM-kaartide arv (tk)</Form.Label>
                    <Form.Control
                        value={form.simCardCount}
                        onChange={(e: any) => {
                            const val = e.target.value;
                    
                            setForm({
                                ...form,
                                simCardCount: val,
                                simCardMonthlyFeeEuros: val > 0 ? form.simCardMonthlyFeeEuros : undefined,
                                simCardPaymentType: val > 0 ? form.simCardPaymentType : undefined,
                            })
                        }}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
            </div>
            {
                form?.simCardCount !== undefined && form?.simCardCount > 0 ? (
                    <div className="row mt-2">
                        <div className="col-12 col-md-6">
                            <Form.Label>SIM-kaardi makse sagedus</Form.Label>
                                <Select
                                    required={false}
                                    isClearable
                                    options={chargingBillingCycleOptions}
                                    value={currentSimCardPaymentOption ?? null}
                                    onChange={(opt: any) => {
                                    if(!opt){
                                        setForm({
                                            ...form,
                                            simCardPaymentType: undefined,
                                            simCardMonthlyFeeEuros: undefined
                                        })
                                    }else{
                                        setForm({
                                            ...form,
                                            simCardPaymentType: opt?.value,
                                            simCardMonthlyFeeEuros: form.simCardMonthlyFeeEuros === undefined ? pricing?.simCardMonthlyFeeEuros : form.simCardMonthlyFeeEuros
                                        })
                                    }

                                    }}
                                    placeholder='Vali sagedus'
                                    className={'w-100 form-control form-select'}
                                />
                        </div>
                        <div className="col-12 col-md-6">
                            <Form.Label>SIM-kaardi kuutasu kaardi kohta (€)</Form.Label>
                            <Form.Control
                                value={form.simCardMonthlyFeeEuros ?? ''}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    simCardMonthlyFeeEuros: onNumberChange(e)
                                })}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                                className={'w-100 form-control' + (form.validated && form.simCardPaymentType && form.simCardMonthlyFeeEuros === undefined ? ' is-invalid' : '')}
                            />
                            <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </div>
                ) : null
            }
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>RFID-kaartide arv (tk)</Form.Label>
                    <Form.Control
                        value={form.rfidPaymentCardCount}
                        onChange={(e: any) => {
                            const val = e.target.value;
                            setForm({
                                ...form,
                                rfidPaymentCardCount: val,
                                rfidPaymentCardMonthlyFeeEuros: val > 0 ? (form.rfidPaymentCardMonthlyFeeEuros === undefined ? pricing?.rfidPaymentCardMonthlyFeeEuros : form.rfidPaymentCardMonthlyFeeEuros) : undefined
                            })
                        }}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
                {
                    form.rfidPaymentCardCount !== undefined && form.rfidPaymentCardCount > 0 ? (
                        <div className="col-12 col-md-6">
                            <Form.Label>RFID-kaardi hind (€)</Form.Label>
                            <Form.Control
                                value={form.rfidPaymentCardMonthlyFeeEuros}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    rfidPaymentCardMonthlyFeeEuros: onNumberChange(e)
                                })}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                        </div>
                    ) : null
                }
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Arveldusteenuse ja arveldamise vahendamisteenuse kuutasu otsiku kohta (€)</Form.Label>
                    <Form.Control
                        value={form.mediationAndBillingMonthlyFeePerNozzleEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            mediationAndBillingMonthlyFeePerNozzleEuros: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Infrastruktuuri kasutamise ühekordne tasu otsiku kohta (€)</Form.Label>
                    <Form.Control
                        value={form.infrastructureUsageOneTimeFeePerNozzleEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            infrastructureUsageOneTimeFeePerNozzleEuros: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Infrastruktuuri kasutamise kuutasu laadija otsiku kohta (€)</Form.Label>
                    <Form.Control
                        value={form.infrastructureUsageMonthlyFeePerNozzleEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            infrastructureUsageMonthlyFeePerNozzleEuros: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Päevatariif (s/kWh)</Form.Label>
                    <Form.Control
                        value={form.gridDayTariffCentsKwh}
                        onChange={(e: any) => setForm({
                            ...form,
                            gridDayTariffCentsKwh: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Öötariif (s/kWh)</Form.Label>
                    <Form.Control
                        value={form.gridNightTariffCentsKwh}
                        onChange={(e: any) => setForm({
                            ...form,
                            gridNightTariffCentsKwh: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-md-6">
                    <Form.Label>Ettemaks enne seadmete paigaldamist (€)</Form.Label>
                    <Form.Control
                        value={form.advancePaymentEuros}
                        onChange={(e: any) => setForm({
                            ...form,
                            advancePaymentEuros: onNumberChange(e)
                        })}
                        type="number"
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>
            </div>
        </>
    );
}