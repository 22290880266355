import { SortDirection } from "common/interfaces/IPageResponse";
import { ICustomerContactPersonSortFields, ICustomerContactPerson } from "components/CustomerContactPersons/models";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CustomerContactPersonsService from "services/customer-contact-persons.service";
import { FormContentBlock } from "components/common/FormContentBlock";
import NewChargingAgreementCustomerForm from "./NewChargingAgreementCustomerForm";
import { CreateChargingAgreementForm } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import NewChargingAgreementMeteringPointForm from "./NewChargingAgreementMeteringPointForm";
import NewChargingAgreementDeviceForm from "./NewChargingAgreementDeviceForm";
import NewChargingAgreementServiceForm from "./NewChargingAgreementServiceForm";
import NewChargingAgreementDetailsForm from "./NewChargingAgreementDetailsForm";
import CompanyService from "services/company.service";
import { ChargingPricing } from "components/ChargingPricings/models";
import NewChargingAgreementPricingForm from "./NewChargingAgreementPricingForm";
import { PERSONAL_CODE_LENGTH } from "common/constants";

interface NewChargingAgreementDataProps {
    form: CreateChargingAgreementForm;
    setForm: (form: CreateChargingAgreementForm) => void;
    pricing?: ChargingPricing;
    setPricing: (pricing: ChargingPricing) => void;
    isEditModal: boolean;
    isOfferModal: boolean;
}

export default function NewChargingAgreementData(
    { form, setForm, pricing, setPricing, isEditModal, isOfferModal }:
    NewChargingAgreementDataProps
) {

    const [contactPersons, setContactPersons] = useState<ICustomerContactPerson[]>([]);
    const [customerLoading, setCustomerLoading] = useState(false);
    const [customerLoaded, setCustomerLoaded] = useState(false);

    const onCustomerSearch = () => {
        setCustomerLoaded(false);
        setCustomerLoading(true);
        CustomerContactPersonsService.getCustomerContactPersons({
            page: 0,
            size: 999,
            sortDirection: SortDirection.DESC,
            sortField: ICustomerContactPersonSortFields.REMOTE_CUSTOMER_ID,
            registryNumber: form.customer?.registryNumber
        }).then(res => {
            if (!res.empty) {
                let firstContact;
                if (form.customer?.registryNumber?.length === PERSONAL_CODE_LENGTH) {
                    firstContact = res.content.find(c => c.registryNumber === form.customer?.registryNumber);
                } else {
                    firstContact = res.content[0] as ICustomerContactPerson;
                }
                
                setForm({
                    ...form,
                    customer: {
                        ...form.customer,
                        isCompany: !firstContact.isPerson,
                        id: firstContact.customerId,
                        customerName: firstContact.customerName,
                        vatRegistryNumber: firstContact.vatRegistryNumber,
                        address: firstContact.city ? firstContact.address : undefined,
                        city: firstContact.city,
                        zip: firstContact.zip,
                        contactPersonCode: firstContact.personalIdentificationCode,
                        contactPersonId: res.content.length === 1 || firstContact.isPerson ? firstContact.id : undefined,
                        contactName: res.content.length === 1 || firstContact.isPerson ? firstContact.contactName : '',
                        email: res.content.length === 1 || firstContact.isPerson ? firstContact.contactEmail : '',
                        phone: res.content.length === 1 || firstContact.isPerson ? firstContact.phone || firstContact.mobileNumber : ''
                    }
                });
                setContactPersons(res.content);
                setCustomerLoaded(true);
                setCustomerLoading(false);
            } else {
                const initForm = {
                    validated: false,
                    chargingStations : [],
                    rfidPaymentCardCount: 0,
                    simCardCount: 0,
                    hasEmergencyMaintenanceService: false,
                    hasChargerMaintenanceService: false,
                    customer: {
                        registryNumber: form.customer?.registryNumber,
                        isCompany: form.customer?.registryNumber?.length !== PERSONAL_CODE_LENGTH
                    }
                };
                setContactPersons([]);
                if (form.customer?.registryNumber!.length !== PERSONAL_CODE_LENGTH) {
                    CompanyService.getByRegistryNumber(form.customer?.registryNumber!).then(data => {
                        setForm({
                            ...initForm,
                            customer: {
                                ...initForm.customer,
                                customerName: data.name,
                                address: data.address,
                                vatRegistryNumber: data.vatRegistryNumber
                            }
                        });
                        setCustomerLoaded(true);
                        setCustomerLoading(false);
                    }).catch(() => {
                        setForm(initForm);
                        setCustomerLoaded(true);
                        setCustomerLoading(false);
                    });
                } else {
                    setForm(initForm);
                    setCustomerLoaded(true);
                    setCustomerLoading(false);
                }
            }

        });
    }

    useEffect(() => {
        if(isEditModal){
            onCustomerSearch();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.customer?.registryNumber])

    return (
        <Form className="new-charging-agreement__container" noValidate validated={form.validated}>
            <FormContentBlock title="Klient">
                <NewChargingAgreementCustomerForm
                    form={form}
                    setForm={setForm}
                    isCompany={!!form.customer?.isCompany}
                    isFormDisabled={false}
                    onSearch={onCustomerSearch}
                    contactPersons={contactPersons}
                    customerLoaded={customerLoaded}
                    customerLoading={customerLoading}
                    isEditModal={isEditModal}
                    isOffer={isOfferModal}
                />
            </FormContentBlock>
            { 
                ((customerLoaded && !isEditModal) || isEditModal) && (
                    <>
                        <FormContentBlock title="Tarbimiskoht">
                            <NewChargingAgreementMeteringPointForm 
                                form={form}
                                setForm={setForm}
                                isEditModal={isEditModal}
                            />
                        </FormContentBlock>
                        {
                            !isEditModal && (
                                <FormContentBlock title="Hinnakiri">
                                    <NewChargingAgreementPricingForm 
                                        form={form}
                                        setForm={setForm}
                                        setPricing={setPricing}
                                        isEditModal={isEditModal}
                                    />
                                </FormContentBlock>
                            )
                        }
                        <FormContentBlock title="Seadmed">
                            <NewChargingAgreementDeviceForm
                                form={form}
                                setForm={setForm}
                                pricing={pricing}
                            />
                        </FormContentBlock>
                        <FormContentBlock title="Teenused">
                            <NewChargingAgreementServiceForm
                                form={form}
                                setForm={setForm}
                                pricing={pricing}
                            />
                        </FormContentBlock>
                        <FormContentBlock title="Leping">
                            <NewChargingAgreementDetailsForm
                                form={form}
                                setForm={setForm}
                                isOfferModal={isOfferModal}
                            />
                        </FormContentBlock>
                    </> 
                )
            }
        </Form>

    )
}