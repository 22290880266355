/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import UpdateElectricityAgreementPackageData from "../components/UpdateElectricityAgreementPackage/UpdateElectricityAgreementPackageData";
import { INDIVIDUAL_PACKAGE_VALUE, UpdateElectricityAgreementPackageModalForm } from "../models";
import { UpdateElectricityAgreementPackageModal } from "common/common-models";
import { ElectricityPackagesService } from "components/ElectricityPackages/ElectricityPackages.service";
import { ElectricityAgreementsService } from "../ElectricityAgreements.service";
import { ElectricityInvoicesService } from "components/ElectricityInvoices/ElectricityInvoices.service";
import moment from "moment";
import { ISimplifiedInvoice } from "components/ElectricityInvoices/models";
import { BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION } from "common/constants/regex";
import { isNotNullOrUndefined } from "common/data-util";

const initialState = {
    validated: false,
    startDate: moment().toString(),
    isJointInvoicePossible: false,
}

export function UpdateElectrictyAgreementPackageModal(props: UpdateElectricityAgreementPackageModal) {
    const [form, setForm] = useState<UpdateElectricityAgreementPackageModalForm>({
        ...initialState,
        isJointInvoicePossible: props.isJointInvoicePossible,
        productionMarginal: props.agreement.productionMarginal,
        email: props.agreement.contactPerson.email,
        eInvoice: props.agreement.customer.eInvoice,
        bankAccountNumber: props.agreement.customer.bankAccountNumber
    });

    const [saving, setSaving] = useState(false);
    const [activePackage, setActivePackage] = useState(null);
    const [earliestPossibleStartDate, setEarliestPossibleStartDate] = useState(
        moment(props.agreement.startDate).toDate()
    )
    
    useEffect(() => {
        getEarliestPossiblePackageStartDate()

        ElectricityPackagesService.getElectricityPackage(props.agreement.consumptionPackage.id, true)
            .then(pckg => {
                setActivePackage(pckg)
                
                setForm({
                    ...form,
                    consumptionMarginal: props.agreement.consumptionMarginal,
                    consumptionPackage: {
                        ...pckg,
                        basePrice: props.agreement.basePriceCentsKwh ?? pckg.basePrice,
                        baseConsumptionProportion: props.agreement.baseConsumptionProportion ?? pckg.baseConsumptionProportion,
                        pdfFooterDisclaimer: (props.agreement.customer.isCompany ? pckg.pdfFooterDisclaimerCompany : pckg.pdfFooterDisclaimerPerson) ?? '',
                        termsAndConditionsId: pckg.terms.id
                    }
                })
            });
    }, [])

    const validateSubmit = () => {
        if (validate()) {
            submit();
        }
    }

    const validate = (): boolean => {
        setForm({ ...form, validated: true });

        return validatePackage() && validatePrice() && validateBankAccountNumber()
    }

    const validateBankAccountNumber = () => {
        if (form.eInvoice) {
            return BANK_ACCOUNT_NUMBER_REGEX_EXPRESSION.test(form.bankAccountNumber!);
        }
        
        return true;
    }

    const validatePackage = (): boolean => {
        if (form.consumptionPackage?.id === INDIVIDUAL_PACKAGE_VALUE) {
            if (!form.consumptionPackage.nameEt || !form.consumptionPackage.termsAndConditionsId || !form.consumptionPackage.contractTitle || !form.consumptionPackage.pdfFooterDisclaimer) {
                return false;
            }
            if (form.consumptionPackage.marketPricePackage ? !form.consumptionPackage.basePriceTextEt : !isNotNullOrUndefined(form.consumptionPackage.basePrice)) {
                return false;
            }
        }

        if(form.consumptionPackage?.dynamicPackage) {
            if(
                !isNotNullOrUndefined(form.consumptionPackage.baseConsumptionProportion) || 
                !form.consumptionPackage.baseFixationType || 
                !isNotNullOrUndefined(form.consumptionPackage.basePrice)
            ) return false;
        }

        return true;
    }

    const validatePrice = (): boolean => {
        if (!isNotNullOrUndefined(form.consumptionMarginal)) {
            return false;
        }
        return true;
    }

    const submit = () => {
        setSaving(true);
        ElectricityAgreementsService.changeElectricityAgreementsPackage(
                props.agreement.id, 
                form
            ).then(() => {
            setSaving(false);
            props.onSuccess?.();
            props.handleModalHide();
        })
        .catch(() => {
            setSaving(false);
        });
    }

    const getEarliestPossiblePackageStartDate = async () => {
        const invoices : ISimplifiedInvoice[] = await ElectricityInvoicesService.findInvoicesForAgreement(props.agreement.contractNumber);
        if(!invoices.length){
            return;
        }

        const sortedInvoices = invoices.sort((a,b) => new Date(b.dateIssued).getTime() - new Date(a.dateIssued).getTime())
        setEarliestPossibleStartDate(
            moment(sortedInvoices[0].dateIssued).add(1, 'M').startOf('month').toDate()
        )
    }

    return (
      <Modal
        show={true}
        size={"xl"}
        dialogClassName="bg-light"
        onHide={props.handleModalHide}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Muuda paketti
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                activePackage && form.consumptionPackage ? (
                    <UpdateElectricityAgreementPackageData 
                        form={form}
                        setForm={setForm}
                        activePackage={activePackage!}
                        agreementStartsInFuture={moment(props.agreement.startDate).isAfter(moment())}
                        earliestPossibleStartDate={earliestPossibleStartDate}
                        hasProductionPackage={!!props.agreement.productionMarginal}
                    />
                ) : (
                    <div className="d-flex align-items-center justify-content-center">
                        <Spinner size={"sm"} animation={"border"} />
                    </div>
                )
            }
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row align-items-end justify-content-end flex-wrap mb-1">
            <Button variant="info"
                    onClick={() => props.handleModalHide()}
                    type="button"
                    className={"d-flex align-items-center"}>
                <span className='px-2'>Tühista</span>
            </Button>
            <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                    className="d-flex align-items-center">
                {
                    !saving &&
                    <span className='px-2'>Muuda</span>
                }
                {
                    saving &&
                    <>
                        <span className='px-2'>Salvestamine…</span>
                        <Spinner size={"sm"} animation={"border"}/>
                    </>
                }
            </Button>
        </Modal.Footer>
      </Modal>    
    );
}