import React, {useState} from 'react';
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import { ICustomerContactPerson } from '../models';
import AddressAutocomplete from 'components/AddressAutocomplete/AddressAutocomplete';
import CustomerContactPersonsService from 'services/customer-contact-persons.service';
import { handleTextInputNumberChange } from 'helpers';
import { isEmailValidAndPresent } from 'helpers/validator';

interface IEditCustomerContactModalProps {
    rejectCloseHandler: () => void;
    submitCloseHandler: () => void;
    contactPerson: ICustomerContactPerson;
}

interface IContactForm {
    phone?: string;
    email?: string;
    address?: string;
    city?: string;
    zip?: string;
    county?: string;
}

export function EditCustomerContactModal({rejectCloseHandler, submitCloseHandler, contactPerson}: IEditCustomerContactModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [validated, setValidated]: [boolean, any] = useState(false);
    const [form, setForm] = useState<IContactForm>({
      phone: contactPerson.phone?.replace(/^(\+)?372/g, '').trim() ?? '',
      email: contactPerson.contactEmail ?? '',
      address: contactPerson.address ?? '',
      city: contactPerson.city,
      zip: contactPerson.zip,
      county: contactPerson.county
    });

    const validateForm = () => {
        if(isEmailInvalid){
            return false;
        }
        return true;
    };

    const validateSubmit = () => {
        setValidated(false);
        const isValidForm = validateForm();
        setValidated(true);
        if (isValidForm) {
            handleSubmit();
        } else {
            setSaving(false);
        }
    };

    const handleSubmit = async () => {
        try{
            setSaving(true);
            setSavingError(false);

            await CustomerContactPersonsService.updateCustomerContactPerson(
                contactPerson.id, {...form}
            );

            setTimeout(() => {
                submitCloseHandler();
            }, 300);
        }catch(e){
            console.error(e);
            setSavingError(true);
        }finally{
            setSaving(false);
        }
    };

    const isEmailInvalid = !isEmailValidAndPresent(form.email);

    return (
        <Modal
            show={true}
            onHide={rejectCloseHandler}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Kliendi muutmine
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form 
                  noValidate
                  onSubmit={(e: any) => e.preventDefault()}
                  id={"contact-form"} 
                  className={'col'}
                >
                    <Form.Group controlId={'form-phone-number'}>
                        <Form.Label>
                            <span className="text-nowrap">Kontakttelefon</span>
                        </Form.Label>
                        <div className="d-flex flex-row justify-content-center">
                            <input
                                type="text"
                                className="mr-1 form-control"
                                style={{ maxWidth: '70px' }}
                                placeholder='+372'
                                disabled
                            />
                            <input
                                type="tel"
                                className="mobile-id-input__main form-control"
                                disabled={saving}
                                value={form.phone}
                                onChange={(e) => setForm({...form, phone: handleTextInputNumberChange(form.phone ?? '', e.target.value)})}
                            />
                        </div>
                        <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-email'}>
                        <Form.Label className={''}>
                            <span className="text-nowrap">E-post</span>
                        </Form.Label>
                        <Form.Control 
                            required
                            minLength={4}
                            value={form.email}
                            onChange={(e) => setForm({
                                ...form,
                                email: e.target.value
                            })}
                            disabled={saving}
                            type="email"
                            isInvalid={validated && isEmailInvalid}
                        />
                        <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={'form-address'}>
                        <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                            <span className="text-nowrap">Aadress</span>
                        </Form.Label>
                        <AddressAutocomplete
                            address={form.address}
                            setAddress={(address) => setForm({
                                ...form,
                                address: address.fullAddress || address.address,
                                city: address.city,
                                zip: address.zip,
                                county: address.county
                            })}
                            disabled={false}
                            required={false}
                        />
                        <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="info"
                        onClick={() => rejectCloseHandler()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
        </Modal>
    );
}