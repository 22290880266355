/* eslint-disable react-hooks/exhaustive-deps */
import { ElectricityPackagesService } from "components/ElectricityPackages/ElectricityPackages.service";
import { ELECITRCITY_PACKAGE_BASE_FIXATION_TYPES, ElectricityPackageBaseFixationType, ElectricityPackageType, IElectricityPackage } from "components/ElectricityPackages/models";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { INDIVIDUAL_PACKAGE_VALUE, UpdateElectricityAgreementPackageModalForm } from "../../models";
import DatePicker from "react-date-picker";
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { getLastMonthWithFirstDay, getLastMonthWithLastDay, getMarketPricePackageProductDescription, onNumberChange } from "helpers";

interface UpdateElectricityAgreementPackageFormProps {
    form: UpdateElectricityAgreementPackageModalForm;
    setForm: (form: UpdateElectricityAgreementPackageModalForm) => void;
    activePackage: IElectricityPackage;
    agreementStartsInFuture: boolean;
    earliestPossibleStartDate: Date;
}

export default function UpdateElectricityAgreementPackageForm(
    { form, setForm, activePackage, agreementStartsInFuture, earliestPossibleStartDate} : UpdateElectricityAgreementPackageFormProps
) {
    const [packages, setPackages] = useState<IElectricityPackage[]>([]);

    useEffect(() => {
        getPackages();
    }, []);

    const getPackages = () => {
        ElectricityPackagesService.getElectricityPackages({
            page: 0,
            size: 999
        }).then(res => {
            let packages = res.content
                .filter((curr: IElectricityPackage) => {
                    if (!form.isJointInvoicePossible && curr.jointInvoicePackage) {
                        return false;
                    }

                    return true;
                }).filter((pckg: IElectricityPackage) => pckg.type === ElectricityPackageType.CONSUMPTION);

            const matchingPackage = packages.find((pckg) => pckg.id === activePackage.id);

            setPackages(matchingPackage ? packages : [...packages, form.consumptionPackage]);
        });
    }

    const packageOptions = packages
        .map(p => ({ value: p.id, label: p.nameEt }));

    packageOptions.push({ value: INDIVIDUAL_PACKAGE_VALUE, label: 'Individuaalne'});

    const setPackageValue = (option?: any) => {
        const p : any = packages.find(p => p.id === option?.value);

        setForm({
            ...form,
            consumptionPackage: {
                id: option?.value === INDIVIDUAL_PACKAGE_VALUE ? INDIVIDUAL_PACKAGE_VALUE : p?.id,
                termsAndConditionsId: p?.terms?.id,
                basePrice: p?.basePrice,
                basePriceTextEt: p?.basePriceTextEt ?? '',
                basePriceTextEn: p?.basePriceTextEn ?? '',
                contractTitle: p?.contractTitle ?? '',
                pdfFooterDisclaimer: (form.isCompany ? p?.pdfFooterDisclaimerCompany : p?.pdfFooterDisclaimerPerson) ?? '',
                monthlyFee: p?.monthlyFee,
                marketPricePackage: !!p?.marketPricePackage,
                pdfProductDescriptionOnInvoice: p?.pdfProductDescriptionOnInvoice,
                externalProductDescriptionOnInvoice: p?.externalProductDescriptionOnInvoice,
                jointInvoicePackage: !!p?.jointInvoicePackage,
                nameEt: p?.nameEt,
                nameEn: p?.nameEn,
                dynamicPackage: !!p?.dynamicPackage,
                baseFixationType: p?.baseFixationType,
                baseConsumptionProportion: p?.baseConsumptionProportion
            },
        })
    }  

    const currentPackageOption = packageOptions.find(p => p.value === form.consumptionPackage?.id);
    const currentBaseFixationType = ELECITRCITY_PACKAGE_BASE_FIXATION_TYPES.find(p => p.value === form.consumptionPackage?.baseFixationType) || null;

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Pakett*</Form.Label>
                    <Select
                        required
                        options={packageOptions}
                        value={currentPackageOption}
                        onChange={setPackageValue}
                        placeholder='Vali elektripakett'
                        className={'w-100 form-control form-select' + (!form.consumptionPackage?.id && form.validated ? ' is-invalid' : '')}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
            </div>
            { currentPackageOption?.value === INDIVIDUAL_PACKAGE_VALUE ?
                (
                    <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>Paketi eestikeelne nimetus*</Form.Label>
                            <Form.Control
                                required
                                value={form.consumptionPackage?.nameEt}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    consumptionPackage: {
                                        ...form.consumptionPackage,
                                        nameEt: e.target.value
                                    }
                                })}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Form.Label>Paketi inglisekeelne nimetus</Form.Label>
                            <Form.Control
                                value={form.consumptionPackage?.nameEn}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    consumptionPackage: {
                                        ...form.consumptionPackage,
                                        nameEn: e.target.value
                                    }
                                })}
                            />
                        </div>
                    </div>
                ) : null
            }
            { !agreementStartsInFuture && (
                    <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label htmlFor="contract-start">Paketi algus*</Form.Label>
                                <DatePicker
                                    className={'d-block' + (form.startDate === undefined && form.validated ? ' invalid is-invalid' : '')}
                                    value={new Date(form.startDate)}
                                    locale='et'
                                    clearIcon={null}
                                    calendarIcon={<CalendarIcon />}
                                    format='dd.MM.yyyy'
                                    minDate={earliestPossibleStartDate}
                                    maxDate={new Date()}
                                    onChange={(event: any) => {
                                        if(!event){
                                            return;
                                        }
                                        setForm({
                                            ...form, 
                                            startDate: new Date(event).toString(),
                                        })
                                    }}
                                    required
                                />
                                <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                            </div>
                    </div>
            )}
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Check
                        className='form-check-input-no-label'
                        id='market-price-check'
                        label='Börsipakett'
                        checked={form.consumptionPackage?.marketPricePackage}
                        disabled={currentPackageOption?.value !== INDIVIDUAL_PACKAGE_VALUE}
                        onChange={(e: any) => {
                            if(e.target.checked){
                                setForm({
                                    ...form,
                                    consumptionPackage: {
                                        ...form.consumptionPackage,
                                        marketPricePackage: true,
                                        dynamicPackage: false,
                                        baseFixationType: undefined,
                                        baseConsumptionProportion: undefined,
                                        basePrice: !form.consumptionPackage?.marketPricePackage ? undefined : form.consumptionPackage.basePrice,
                                        basePriceTextEt: !form.consumptionPackage?.marketPricePackage ? !form.consumptionPackage?.basePriceTextEt ? 'Börsihind + marginaal' : form.consumptionPackage.basePriceTextEt : '',
                                        basePriceTextEn: !form.consumptionPackage?.marketPricePackage ? !form.consumptionPackage?.basePriceTextEn ? 'Market price + margin' : form.consumptionPackage.basePriceTextEn : ''
                                    }
                                })
                            }else{
                                setForm({
                                    ...form,
                                    consumptionPackage: {
                                        ...form.consumptionPackage,
                                        marketPricePackage: false,
                                        basePrice: undefined
                                    }
                                })
                            }
                        }
                        }
                        type='checkbox'
                    />
                    { form.isJointInvoicePossible && (
                        <Form.Check
                            className='form-check-input-no-label'
                            id='joint-invoice-check'
                            label='Ühisarve'
                            checked={form.consumptionPackage?.jointInvoicePackage}
                            disabled={currentPackageOption?.value !== INDIVIDUAL_PACKAGE_VALUE}
                            onChange={(e: any) => setForm({
                                ...form,
                                consumptionPackage: {
                                    ...form.consumptionPackage,
                                    jointInvoicePackage: !form.consumptionPackage?.jointInvoicePackage,
                                }
                            })}
                            type='checkbox'
                        />
                    )}
                    <Form.Check
                        className='form-check-input-no-label'
                        id='dynamic-package-check'
                        label='Dünaamiline pakett'
                        checked={form.consumptionPackage?.dynamicPackage}
                        disabled={currentPackageOption?.value !== INDIVIDUAL_PACKAGE_VALUE}
                        onChange={(e: any) => {
                            if(e.target.checked){
                                setForm({
                                    ...form,
                                    consumptionPackage: {
                                        ...form.consumptionPackage,
                                        dynamicPackage: true,
                                        marketPricePackage: false,
                                        basePrice: !form.consumptionPackage?.dynamicPackage ? undefined : form.consumptionPackage.basePrice,
                                    }
                                })
                            }else{
                                setForm({
                                    ...form,
                                    consumptionPackage: {
                                        ...form.consumptionPackage,
                                        dynamicPackage: false,
                                        basePrice: undefined
                                    }
                                })
                            }
                        }}
                        type='checkbox'
                    />
                </div>
            </div>
            {
                form.consumptionPackage?.dynamicPackage && (
                    <>
                        <div className="row mt-3">
                            <div className="col-12 col-md-6">
                                <Form.Label>
                                    <span className="text-nowrap">Fikseerimise tüüp*</span>
                                </Form.Label>
                                <Select
                                        className={'form-select' + (form.validated && !form.consumptionPackage?.baseFixationType ? ' is-invalid' : '')} 
                                        options={ELECITRCITY_PACKAGE_BASE_FIXATION_TYPES}
                                        value={currentBaseFixationType}
                                        onChange={(val: any) => setForm({
                                            ...form,
                                            consumptionPackage: {
                                                ...form.consumptionPackage,
                                                baseFixationType: val.value
                                            }
                                        })}
                                        placeholder='Vali fikseerimise tüüp'
                                        required
                                        isDisabled={currentPackageOption?.value !== INDIVIDUAL_PACKAGE_VALUE}
                                    />
                                <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>    
                            </div>
                            <div className="col-12 col-md-6">
                                <Form.Label>Fikseeritud tarbimise osakaal {form.consumptionPackage?.baseFixationType === ElectricityPackageBaseFixationType.PERCENTAGE ? "(%)" : "(MWh)"}</Form.Label>
                                <Form.Control
                                    value={form.consumptionPackage?.baseConsumptionProportion}
                                    onChange={(e : any) => setForm({
                                            ...form,
                                            consumptionPackage: {
                                                ...form.consumptionPackage,
                                                baseConsumptionProportion: onNumberChange(e)
                                            }
                                        })
                                    }
                                    required
                                    type="number"
                                />
                                <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                            </div>
                        </div>
                    </>
                )
            }
            {currentPackageOption?.value === INDIVIDUAL_PACKAGE_VALUE ? (
                <>
                   <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>Arve toote kirjeldus PDF-il</Form.Label>
                            <Form.Control
                                value={form.consumptionPackage?.pdfProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            pdfProductDescriptionOnInvoice: e.target.value
                                        }
                                    })
                                }
                                type="text"
                            />
                        </div>
                    </div>
                    <div className='text-break text-gray-700'>{form.consumptionPackage?.pdfProductDescriptionOnInvoice ?? (form.consumptionPackage?.marketPricePackage ? getMarketPricePackageProductDescription(form.consumptionPackage.nameEt) : "Elektrienergia universaalteenuse hinnaga")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) mõõtepunktis <b>X</b> aadressiga <b>X</b></div>
                    <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>Arve toote kirjeldus meritis</Form.Label>
                            <Form.Control
                                className='col-12 col-md-6'
                                value={form.consumptionPackage?.externalProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        consumptionPackage: {
                                            ...form.consumptionPackage,
                                            externalProductDescriptionOnInvoice: e.target.value
                                        }
                                    })
                                }
                                maxLength={150}
                                type="text"
                            />
                        </div>
                    </div>
                    <div className='col-12 p-0 text-break text-gray-700'>{form.consumptionPackage?.externalProductDescriptionOnInvoice ?? (form.consumptionPackage?.marketPricePackage ? getMarketPricePackageProductDescription(form.consumptionPackage.nameEt) : "Elektri universaalteenus")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) - (<b>X</b>) <b>X</b></div>
                </>
            ) : null} 
        </>
    );
}