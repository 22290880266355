import { EMAIL_REGEX_EXPRESSION, INVALID_EMAIL_DOMAIN_REGEX_EXPRESSION } from "common/constants/regex";

export const isValidEmail = (email: string) => {
  if(INVALID_EMAIL_DOMAIN_REGEX_EXPRESSION.test(email)) return false;

  return EMAIL_REGEX_EXPRESSION.test(email);
}

export const isEmailValidAndPresent = (email?: string) => {
  if(!email) return false;
  return isValidEmail(email.trim());
}

export const isValidCommaSeperatedEmail = (email: string) => {
  return email.split(",").every(email => isValidEmail(email.trim()));
}