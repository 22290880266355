import React from "react";
import { Form } from "react-bootstrap";
import { UpdateElectricityAgreementPackageModalForm } from "../../models";
import { FormContentBlock } from "../../../common/FormContentBlock";
import UpdateElectricityAgreementPackageForm from "./UpdateElectricityAgreementPackageForm";
import UpdateElectricityAgreementPackageConsumptionPriceForm from "./UpdateElectricityAgreementPackageConsumptionPriceForm";
import UpdateElectricityAgreementPackageProductionPriceForm from "./UpdateElectricityAgreementPackageProductionPriceForm";
import UpdateElectricityAgreementPackageDetailsForm from "./UpdateElectricityAgreementPackageDetailsForm";
import { IElectricityPackage } from "components/ElectricityPackages/models";

interface UpdateElectricityAgreementPackageDataProps {
    form: UpdateElectricityAgreementPackageModalForm;
    setForm: (form: UpdateElectricityAgreementPackageModalForm) => void;
    activePackage: IElectricityPackage;
    agreementStartsInFuture: boolean;
    earliestPossibleStartDate: Date;
    hasProductionPackage: boolean;
}

export default function UpdateElectricityAgreementPackageData(
    { form, setForm, activePackage, agreementStartsInFuture, earliestPossibleStartDate, hasProductionPackage }:
    UpdateElectricityAgreementPackageDataProps
) {

    return (
        <Form className="change-electricity-agreement-package__container" noValidate validated={form.validated}>
            <FormContentBlock title="Pakett">
                <UpdateElectricityAgreementPackageForm 
                    form={form}
                    setForm={setForm}
                    activePackage={activePackage}
                    agreementStartsInFuture={agreementStartsInFuture}
                    earliestPossibleStartDate={earliestPossibleStartDate}
                />
            </FormContentBlock>
            <FormContentBlock title="Elektri müügihind kliendile">
                <UpdateElectricityAgreementPackageConsumptionPriceForm 
                  form={form}
                  setForm={setForm}
                />
            </FormContentBlock>
            {hasProductionPackage && (
                 <FormContentBlock title="Elektri ostuhind kliendile">
                    <UpdateElectricityAgreementPackageProductionPriceForm 
                    form={form}
                    setForm={setForm}
                    />
                </FormContentBlock>
            )}
            <FormContentBlock title="Tingimused">
                <UpdateElectricityAgreementPackageDetailsForm 
                    form={form}
                    setForm={setForm} 
                />
            </FormContentBlock>
        </Form>

    )
}