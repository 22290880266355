import React, {useState} from 'react';
import {Button, Modal, Spinner } from "react-bootstrap";
import { ChargingBillingCycle, ChargingAgreement } from '../models';
import NewChargingAgreementData from '../components/NewChargingAgreement/NewChargingAgreementData';
import { ChargingAgreementsService } from '../ChargingAgreements.service';
import { ChargingPricing } from 'components/ChargingPricings/models';
import { ModalProps } from 'common/common-models';
import { isNotNullOrUndefined } from 'common/data-util';
import { isEmailValidAndPresent } from 'helpers/validator';

export enum EditChargingAgreementModalType {
    NEW,
    EDIT,
    OFFER
}

interface IEditChargingAgreementModalProps extends Omit<ModalProps, "modalType"> {
    chargingAgreement?: ChargingAgreement;
    modalType: EditChargingAgreementModalType
    isOffer?: boolean;
}

export interface CreateChargingAgreementForm {
    validated: boolean;
    customer?: ChargingAgreementCustomerForm;
    address?: string;
    city?: string;
    zip?: string;
    externalChargingStationId?: string;
    meteringPoint?: ChargingAgreementMeteringPointForm;
    purchaseOptionId?: number;
    serviceOptionId?: number;
    thirdPartyCompensationOptionId?: number;
    chargingStations: ChargingAgreementStationForm[]
    pricingId?: number;
    termsAndConditionsId?: number;
    contractPeriodId?: number;
    startDate?: Date;
    endDate?: Date;
    offerDeadline?: Date;
    hasEmergencyMaintenanceService: boolean;
    hasChargerMaintenanceService: boolean;
    chargerMaintenanceType?: ChargingBillingCycle;
    simCardCount?: number;
    simCardPaymentType?: ChargingBillingCycle;
    rfidPaymentCardCount?: number;
    advancePaymentEuros?: number;
    installationFeeEuros?: number;
    devicesRentalMonthlyFeeEuros?: number;
    devicesFullServiceRentalMonthlyFeeEuros?: number;
    devicesCostEuros?: number;
    thirdPartyCompensationNetoFeeEuros?: number;
    maintenanceMonthlyFeeEuros?: number;
    rfidPaymentCardMonthlyFeeEuros?: number;
    simCardMonthlyFeeEuros?: number;
    mediationAndBillingMonthlyFeePerNozzleEuros?: number;
    infrastructureUsageOneTimeFeePerNozzleEuros?: number;
    infrastructureUsageMonthlyFeePerNozzleEuros?: number;
    chargingServiceMonthlyFeeEuros?: number;
    thirdPartyChargingMarginalCentsKwh?: number;
    gridDayTariffCentsKwh?: number;
    gridNightTariffCentsKwh?: number;
}

export interface ChargingAgreementMeteringPointForm {
    id?: number;
    eic?: string;
    address?: string;
    newAddress?: string;
    productionCapacity?: number;
}

export interface ChargingAgreementCustomerForm {
    id?: number;
    eic?: string;
    isCompany?: boolean;
    customerName?: string;
    registryNumber?: string;
    vatRegistryNumber?: string;
    contactName?: string;
    contactPersonId?: number;
    phone?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    contactPersonCode?: string;
    address?: string;
    city?: string;
    zip?: string;
    county?: string;
}

export interface ChargingAgreementStationForm {
    serialNumber?: string;
    latitude?: string;
    longitude?: string;
    installationDate?: Date;
    installationExtraFeeEuros?: number;
    nozzleCount?: number;
    stationModelId?: number
}

export const NETO_COMPENSATION_ID = 3;
export const FULL_SERVICE_RENT_PURCHASE_OPTION_ID = 3;
export const RENT_PURCHASE_OPTION_ID = 2;

export function EditChargingAgreementModal(props: IEditChargingAgreementModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [form, setForm]: [CreateChargingAgreementForm, any] = useState({
        validated: false,
        customer: props.chargingAgreement ? {
            id: props.chargingAgreement.customer.id,
            eic: props.chargingAgreement.customer.eic,
            contactPersonId: props.chargingAgreement.contactPerson.id,
            registryNumber: props.chargingAgreement.customer.registryNumber
        } : undefined,
        hasEmergencyMaintenanceService: props.chargingAgreement?.hasEmergencyMaintenanceService ?? false,
        hasChargerMaintenanceService: props.chargingAgreement?.hasChargerMaintenanceService ?? false,
        simCardCount: props.chargingAgreement?.simCardCount ?? undefined,
        rfidPaymentCardCount: props.chargingAgreement?.rfidPaymentCardCount ?? undefined,
        chargingStations: props.chargingAgreement ? props.chargingAgreement.chargingStations.map(station => ({
            ...station,
            stationModelId: station.model.id
        }) as ChargingAgreementStationForm) : [],
        meteringPoint: props.chargingAgreement?.meteringPoint ?? undefined,
        address: props.chargingAgreement?.address ?? undefined,
        city: props.chargingAgreement?.city ?? undefined,
        zip: props.chargingAgreement?.zip ?? undefined,
        externalChargingStationId: props.chargingAgreement?.externalChargingStationId ?? undefined,
        purchaseOptionId: props.chargingAgreement?.purchaseOption.id ?? undefined,
        serviceOptionId: props.chargingAgreement?.serviceOption.id ?? undefined,
        thirdPartyCompensationOptionId: props.chargingAgreement?.thirdPartyCompensationOption.id ?? undefined,
        contractPeriodId: props.chargingAgreement?.contractPeriod.id ?? undefined,
        termsAndConditionsId: props.chargingAgreement?.termsAndConditions.id ?? undefined,
        startDate: props.chargingAgreement?.startDate ? new Date(props.chargingAgreement?.startDate) : undefined,
        endDate: props.chargingAgreement?.endDate ? new Date(props.chargingAgreement?.endDate) : undefined,
        chargerMaintenanceType: props.chargingAgreement?.chargerMaintenanceType ?? undefined,
        simCardPaymentType: props.chargingAgreement?.simCardPaymentType ?? undefined,
        advancePaymentEuros: props.chargingAgreement?.advancePaymentEuros ?? undefined,
        installationFeeEuros: props.chargingAgreement?.pricing.installationFeeEuros ?? undefined,
        devicesRentalMonthlyFeeEuros: props.chargingAgreement?.pricing.devicesRentalMonthlyFeeEuros ?? undefined,
        devicesFullServiceRentalMonthlyFeeEuros: props.chargingAgreement?.pricing.devicesFullServiceRentalMonthlyFeeEuros ?? undefined,
        devicesCostEuros: props.chargingAgreement?.pricing.devicesCostEuros ?? undefined,
        thirdPartyCompensationNetoFeeEuros: props.chargingAgreement?.pricing.thirdPartyCompensationNetoFeeEuros ?? undefined,
        maintenanceMonthlyFeeEuros: props.chargingAgreement?.pricing.maintenanceMonthlyFeeEuros ?? undefined,
        rfidPaymentCardMonthlyFeeEuros: props.chargingAgreement?.pricing.rfidPaymentCardMonthlyFeeEuros ?? undefined,
        simCardMonthlyFeeEuros: props.chargingAgreement?.pricing.simCardMonthlyFeeEuros ?? undefined,
        mediationAndBillingMonthlyFeePerNozzleEuros: props.chargingAgreement?.pricing.mediationAndBillingMonthlyFeePerNozzleEuros ?? undefined,
        infrastructureUsageOneTimeFeePerNozzleEuros: props.chargingAgreement?.pricing.infrastructureUsageOneTimeFeePerNozzleEuros ?? undefined,
        infrastructureUsageMonthlyFeePerNozzleEuros: props.chargingAgreement?.pricing.infrastructureUsageMonthlyFeePerNozzleEuros ?? undefined,
        chargingServiceMonthlyFeeEuros: props.chargingAgreement?.pricing.chargingServiceMonthlyFeeEuros ?? undefined,
        thirdPartyChargingMarginalCentsKwh: props.chargingAgreement?.pricing.thirdPartyChargingMarginalCentsKwh ?? undefined,
        gridDayTariffCentsKwh: props.chargingAgreement?.pricing.gridDayTariffCentsKwh ?? undefined,
        gridNightTariffCentsKwh: props.chargingAgreement?.pricing.gridNightTariffCentsKwh ?? undefined
    });
    const [pricing, setPricing] = useState<ChargingPricing>();

    const isEditModal = props.modalType === EditChargingAgreementModalType.EDIT;
    const isOfferModal = props.modalType === EditChargingAgreementModalType.OFFER;

    const validateSubmit = () => {
        if (validate()) {
            submit();
        }
    }

    const validateCustomer = () => {
        if (!form.customer?.registryNumber || !form.customer?.address || !isEmailValidAndPresent(form.customer.email) || !form.customer?.phone) return false;
        return true;
    }

    const validateMeteringPoint = () => {
        if(!form.meteringPoint?.eic || (!form.meteringPoint.address && !form.meteringPoint.newAddress)) return false;
        if(!form.externalChargingStationId) return false; 
        return true;
    }

    const validateDevices = () => {
        if(!form.purchaseOptionId || !form.thirdPartyCompensationOptionId) return false;
        if(form.thirdPartyCompensationOptionId === NETO_COMPENSATION_ID && !isNotNullOrUndefined(form.thirdPartyCompensationNetoFeeEuros)) return false;
        if(form.purchaseOptionId === FULL_SERVICE_RENT_PURCHASE_OPTION_ID && !isNotNullOrUndefined(form.devicesFullServiceRentalMonthlyFeeEuros)) return false;
        if(form.purchaseOptionId === RENT_PURCHASE_OPTION_ID && !isNotNullOrUndefined(form.devicesRentalMonthlyFeeEuros)) return false;

        return true;
    }

    const validateServices = () => {
        if(!form.serviceOptionId) return false;
        if(form.hasChargerMaintenanceService && (!form.chargerMaintenanceType || !isNotNullOrUndefined(form.maintenanceMonthlyFeeEuros))) return false;
        if(form.simCardPaymentType && !isNotNullOrUndefined(form.simCardMonthlyFeeEuros)) return false;

        return true;
    }

    const validateCargingStations = () => {
        return form.chargingStations.every(chargingStation => 
            chargingStation.serialNumber && chargingStation.stationModelId
        );
    }

    const validateAgreement = () => {
        if((!isEditModal && !form.pricingId) || !form.termsAndConditionsId || !form.contractPeriodId || (!isOfferModal && !form.startDate) || (isOfferModal && !form.offerDeadline)) return false;
        
        return true;
    }

    const validate = () => {
        setForm({ ...form, validated: true });
        
        return validateCustomer() && validateMeteringPoint() && validateDevices() && 
            validateServices() && validateCargingStations() && validateAgreement();
    }

    const submit = async () => {
        try{
            setSaving(true);
            if(isEditModal){
                await ChargingAgreementsService.updateChargingAgreement(form, props.chargingAgreement!.id);
            }else if (isOfferModal){
                await ChargingAgreementsService.createChargingAgreementOffer(form);
            }else{
                await ChargingAgreementsService.createChargingAgreement(form);
            }
            setSavingError(false);      
            setSaving(false);
            props.handleModalHide();
            props.onSuccess?.()
        }catch(e){
            console.error(e);
            setSaving(false);
            setSavingError(true);
        }
    };

    const getModalTitle = () => {             
        switch(props.modalType){
            case EditChargingAgreementModalType.NEW:
                return "Laadimislepingu loomine";
            case EditChargingAgreementModalType.OFFER:
                return "Pakkumise loomine";
            case EditChargingAgreementModalType.EDIT:
                return "Laadimislepingu uuendamine";
        }
    }

    return (
        <Modal
            show={true}
            size={"xl"}
            dialogClassName="bg-light"
            onHide={props.handleModalHide}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    <span>
                        {getModalTitle()}
                    </span>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <NewChargingAgreementData 
                    form={form}
                    setForm={setForm}
                    pricing={pricing}
                    setPricing={setPricing}
                    isEditModal={isEditModal}
                    isOfferModal={isOfferModal}
                />
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
        </Modal>
    );
}
