import React, {PureComponent} from "react";
import { ChargingAgreementsSearchBar } from "components/ChargingAgreements/ChargingAgreementsSearchBar";
import { ChargingAgreementsTable } from "components/ChargingAgreements/ChargingAgreementsTable";
import { IPageResponse, SortDirection } from "common/interfaces/IPageResponse";
import { ChargingAgreementFilters, ChargingAgreement, ChargingAgreementSortFields } from "components/ChargingAgreements/models";
import { ChargingAgreementsService } from "components/ChargingAgreements/ChargingAgreements.service";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { IPageRequest } from "common/interfaces/IPageRequest";
import { EditChargingAgreementModal, EditChargingAgreementModalType } from "components/ChargingAgreements/modals/EditChargingAgreementModal";
import { CancelChargingAgreementOfferConfirmModal } from "components/ChargingAgreements/modals/CancelChargingAgreementOfferConfirmModal";
import { PdfPreview } from "components/ElectricityAgreements/models";
import PDFPreviewModal from "components/Modals/PDFPreviewModal/PDFPreviewModal";
import printJS from "print-js";

interface ChargingAgreementsState {
  chargingAgreements?: IPageResponse<ChargingAgreement> | undefined;
  chargingAgreement?: ChargingAgreement;
  pageRequest: IPageRequest<ChargingAgreementSortFields>;
  filters: ChargingAgreementFilters;
  isCreateModalVisible: boolean;
  isEditModalVisible: boolean;
  isOfferModalVisible: boolean;
  isCancelModalVisible: boolean;
  isRequesting: boolean;
  pdfPreview?: PdfPreview;
  pdfPreviewLoading?: number;
  isDownloading: boolean;
}

const searchApi = AwesomeDebouncePromise(
    request => ChargingAgreementsService.findChargingAgreements(request),
    300
);

export default class ChargingAgreements extends PureComponent<any, ChargingAgreementsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isCreateModalVisible: false,
            isEditModalVisible: false,
            isOfferModalVisible: false,
            isRequesting: false,
            isDownloading: false,
            isCancelModalVisible: false,
            pageRequest: {
                page: 0,
                size: 20,
                sortDirection: SortDirection.ASC,
                sortField: ChargingAgreementSortFields.ID,
                searchQuery: null
            },
            filters: { },
        };
        this.props.onLoadValidatePage();
    }

    findChargingAgreements = () => {
        ChargingAgreementsService.findChargingAgreements(this.state.pageRequest).then((response: IPageResponse<ChargingAgreement>) => {
            this.setState({
                chargingAgreements: response
            });
        });
    };

    searchChargingAgreements = (request: any) => {
        this.setState({
            isRequesting: true
        });
        searchApi(request).then((response: IPageResponse<ChargingAgreement>) => {
            this.setState({
                chargingAgreements: response, isRequesting: false
            });
        })
    };

    onPageChange = (targetPage: number) => {
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                page: targetPage - 1
            }
        }, () => this.findChargingAgreements());
    };

    handleSortClick = (sortField: ChargingAgreementSortFields) => {
        let sortDirection = SortDirection.ASC;
        if (this.state.pageRequest.sortField.match(sortField)) {
            sortDirection = this.state.pageRequest.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
        }
        this.setState({
            pageRequest: {
                ...this.state.pageRequest,
                sortDirection,
                sortField
            }
        }, () => this.onPageChange(1))
    };

    handleFilterChange = (change: ChargingAgreementFilters) => {
        this.setState({
            chargingAgreements: undefined,
            pageRequest: {
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField
            },
            filters: {
                ...this.state.filters,
                ...change
            }
        }, () => {
            this.searchChargingAgreements({
                page: 0,
                size: this.state.pageRequest.size,
                sortDirection: this.state.pageRequest.sortDirection,
                sortField: this.state.pageRequest.sortField,
                searchQuery: this.state.filters.search,
                status: this.state.filters.status
            });
        });
    };

    cancelChargingAgreementOffer = (chargingAgreementId : number) => {
        ChargingAgreementsService.cancelChargingAgreementOffer(chargingAgreementId)
            .then(() => {
                this.findChargingAgreements()
            });
    }

    openCancelChargingAgreementModal = (chargingAgreement: ChargingAgreement) => {
        this.setState({
            isCancelModalVisible: true,
            chargingAgreement
        })
    }

    openEditChargingAgreementModal = (chargingAgreement: ChargingAgreement) => {
      this.setState({
          isEditModalVisible: true,
          chargingAgreement
      })
    };

    openCreateChargingAgreementModal = () => {
        this.setState({
            isCreateModalVisible: true
        })
    };

    openCreateChargingAgreementOfferModal = () => {
        this.setState({
            isOfferModalVisible: true
        })
    };

    getModalType = () => {
        if(this.state.isOfferModalVisible) return EditChargingAgreementModalType.OFFER
        if(this.state.isEditModalVisible) return EditChargingAgreementModalType.EDIT
        return EditChargingAgreementModalType.NEW;
    }

    getPdfPreview = (chargingAgreement: ChargingAgreement) => {
        this.setState({
            ...this.state,
            pdfPreviewLoading: chargingAgreement.id
        })
        ChargingAgreementsService.getChargingAgreementFile(chargingAgreement.id)
            .then((response) => this.setState({
                ...this.state,
                pdfPreview: {
                    agreementId: chargingAgreement.id,
                    objectUrl: response.data,
                    fileName: `${chargingAgreement.contractNumber}.pdf`
                }
        })).finally(() => this.setState({
            ...this.state,
            pdfPreviewLoading: undefined
        }))
    }

    downloadChargingAgremeentFile = (pdfPreview: PdfPreview) : void => {
        this.setState({
            ...this.state,
            isDownloading: true
        });
        ChargingAgreementsService.getChargingAgreementFileWithDownload(
            pdfPreview.agreementId, 
            pdfPreview.fileName
        ).finally(() => this.setState({
            ...this.state,
            isDownloading: false
        }));
    }

    componentDidMount(): void {
        this.findChargingAgreements()
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                      <ChargingAgreementsSearchBar
                        openCreateChargingAgreementModal={this.openCreateChargingAgreementModal}
                        openCreateChargingAgreementOfferModal={this.openCreateChargingAgreementOfferModal}
                        filters={this.state.filters}
                        onFilter={this.handleFilterChange}
                      />
                      {this.state.chargingAgreements !== undefined ?
                            <ChargingAgreementsTable
                                onPageChange={this.onPageChange}
                                onSort={this.handleSortClick}
                                sortDirection={this.state.pageRequest.sortDirection}
                                sortField={this.state.pageRequest.sortField}
                                chargingAgreements={this.state.chargingAgreements}
                                openEditChargingAgreementModal={this.openEditChargingAgreementModal}
                                openCancelChargingAgreementModal={this.openCancelChargingAgreementModal}
                                getPdfPreview={this.getPdfPreview}
                                pdfPreviewLoading={this.state.pdfPreviewLoading}
                            /> :
                            null
                        }
                    </div>
                </div>    
                {
                    (this.state.isCreateModalVisible || this.state.isEditModalVisible || this.state.isOfferModalVisible) && (
                        <EditChargingAgreementModal
                            modalType={this.getModalType()!}
                            handleModalHide={() => {
                                this.setState({
                                    isOfferModalVisible: false,
                                    isEditModalVisible: false,
                                    isCreateModalVisible: false,
                                });
                            }}
                            chargingAgreement={this.state.chargingAgreement}
                            onSuccess={() => this.findChargingAgreements()}
                        />
                    )
                }
                {
                    this.state.isCancelModalVisible && (
                        <CancelChargingAgreementOfferConfirmModal
                            handleConfirmReject={() => this.setState({isCancelModalVisible: false, chargingAgreement: undefined})}
                            handleConfirmSubmit={() => {
                                this.cancelChargingAgreementOffer(this.state.chargingAgreement!.id)
                                this.setState({isCancelModalVisible: false, chargingAgreement: undefined})
                            }}
                            chargingAgreementContractNumber={this.state.chargingAgreement?.contractNumber!}
                        />
                    )
                }
                <PDFPreviewModal
                    show={!!this.state.pdfPreview}
                    onHide={() => this.setState({
                        ...this.state,
                        pdfPreview: undefined
                    })}
                    onDownload={() => this.downloadChargingAgremeentFile(this.state.pdfPreview!)}
                    isDownloading={this.state.isDownloading}
                    onPrint={() => printJS({printable: this.state.pdfPreview?.objectUrl!, type: "pdf"})}
                    preview={this.state.pdfPreview}
                />
            </div>
        );
    }
}