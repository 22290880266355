/* eslint-disable react-hooks/exhaustive-deps */
import { TermsAndConditionsService } from "components/TermsAndConditions/TermsAndConditions.service";
import React, { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { INDIVIDUAL_PACKAGE_VALUE, UpdateElectricityAgreementPackageModalForm } from "../../models";

interface UpdateElectricityAgreementPackageDetailsFormProps {
    form: UpdateElectricityAgreementPackageModalForm;
    setForm: (form: UpdateElectricityAgreementPackageModalForm) => void;
}

export default function UpdateElectricityAgreementPackageDetailsForm(
    { form, setForm } : UpdateElectricityAgreementPackageDetailsFormProps
) {

    const [termsAndConditionsOptions, setTermsAndConditionsOptions] = useState<any[]>([]);

    useEffect(() => {
        getTermsAndConditions();
    }, []);

    const getTermsAndConditions = () => {
        TermsAndConditionsService.getTermsAndConditions()
            .then(res => setTermsAndConditionsOptions(
                res.content.map(c => ({ value: c.id, label: c.name }))
            ));
    }

    const currentTermsAndConditionsOption = termsAndConditionsOptions.find(t => t.value === form.consumptionPackage?.termsAndConditionsId) || null;

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>Lepingu pealkiri*</Form.Label>
                    <Form.Control
                        value={form.consumptionPackage?.contractTitle}
                        disabled={form.consumptionPackage?.id !== INDIVIDUAL_PACKAGE_VALUE}
                        onChange={(e: any) => setForm({
                            ...form, consumptionPackage: {
                                ...form.consumptionPackage,
                                contractTitle: e.target.value
                            }
                        })}
                        required
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                { form.consumptionPackage?.id === INDIVIDUAL_PACKAGE_VALUE ? (
                    <div className="col-12 col-md-6">
                        <Form.Label>Tüüptingimused*</Form.Label>
                        <Select
                            options={termsAndConditionsOptions}
                            value={currentTermsAndConditionsOption}
                            onChange={(val: any) => setForm({
                                ...form,
                                consumptionPackage: {
                                    ...form.consumptionPackage,
                                    termsAndConditionsId: val?.value
                                }
                            })}
                            className={'w-100 form-control form-select' + (!form.consumptionPackage.termsAndConditionsId && form.validated ? ' is-invalid' : '')}
                            placeholder='Vali elektripaketi tüüptingimus'
                            required
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </div>
                ) : null }                
            </div>           
            <div className="row mt-3">
                <div className="col-12">
                    <Form.Label>
                        Lepingu eritingimused*
                        <OverlayTrigger delay={250}
                            overlay={
                                <Tooltip id={`tooltip-base-price-text`}>
                                    <p className='m-1'>Kuvatakse lepingu jaluses</p>
                                    <p>Tingimuste vormindamiseks on kasutatud HTML süntaksi</p>
                                    <p>Reavahe tekitamise jaoks kasutada sümbolit <code>{'<br />'}</code></p>
                                    <p>Tekstilõiku (paragraph) saab lisada järgmiselt: <code>{'<p>Lõigu tekst</p>'}</code></p>
                                </Tooltip>} placement={"right"}>
                                <i className='icon__info icon__info--masked bg-dark ml-2 mb-n1'></i>
                        </OverlayTrigger>                        
                    </Form.Label>
                    <Form.Control
                        as="textarea"
                        value={form.consumptionPackage?.pdfFooterDisclaimer}
                        rows={6}
                        disabled={form.consumptionPackage?.id !== INDIVIDUAL_PACKAGE_VALUE}
                        required
                        onChange={(e: any) => setForm({
                            ...form, consumptionPackage: {
                                ...form.consumptionPackage,
                                pdfFooterDisclaimer: e.target.value
                            }
                        })}
                    />
                </div>
            </div>
        </>
    );
}