import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ElectricityAgreementForm, INDIVIDUAL_PACKAGE_VALUE } from "../../models";
import { onNumberChange } from "helpers";

interface NewElectricityAgreementPriceFormProps {
    form: ElectricityAgreementForm;
    setForm: (form: ElectricityAgreementForm) => void;
}

export default function NewElectricityAgreementPriceForm(
    { form, setForm } : NewElectricityAgreementPriceFormProps
) {

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Form.Label>{form.consumptionPackage.dynamicPackage ? 'Fikseeritud tarbimise hind KM-ta' : 'Baashind KM-ta'} (s/kWh)*</Form.Label>
                    <Form.Control
                        type="number"
                        disabled={(form.consumptionPackage.id !== INDIVIDUAL_PACKAGE_VALUE && !form.consumptionPackage.dynamicPackage) || form.consumptionPackage.marketPricePackage}
                        value={form.consumptionPackage.basePrice ?? ''}
                        step={0.001}
                        onChange={(e: any) => setForm({
                            ...form, consumptionPackage: {
                                ...form.consumptionPackage,
                                basePrice: onNumberChange(e)
                            }
                        })}
                        required={!form.consumptionPackage.marketPricePackage}
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>   
                </div>
                <div className="col-12 col-md-6">
                    <Form.Label>Kuutasu KM-ta (€)</Form.Label>
                    <Form.Control
                        type="number"
                        disabled={form.consumptionPackage.id !== INDIVIDUAL_PACKAGE_VALUE}
                        value={form.consumptionPackage.monthlyFee ?? ''}
                        step={0.001}
                        onChange={(e: any) => setForm({
                            ...form, consumptionPackage: {
                                ...form.consumptionPackage,
                                monthlyFee: onNumberChange(e)
                            }
                        })}
                        onWheel={(e: any) => e.target.blur()}
                    />
                </div>                
            </div>
            <div className="row mt-3">
                <div className="col-12 col-md-6">
                    <Form.Label>{form.consumptionPackage.dynamicPackage ? 'Börsihinnaga tarbimise marginaal KM-ta' : 'Marginaal KM-ta'} (s/kWh)*</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        value={form.consumptionMarginal}
                        step={0.001}
                        onChange={(e: any) => setForm({
                            ...form,
                            consumptionMarginal: onNumberChange(e)
                        })}
                        onWheel={(e: any) => e.target.blur()}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                </div>
                {
                    form.consumptionPackage.id !== INDIVIDUAL_PACKAGE_VALUE ? (
                        <div className="col-12 col-md-6">
                            <Form.Label>
                                {form.consumptionPackage.dynamicPackage ? 'Fikseeritud tarbimise hinna tekst' : 'Baashinna tekst'}
                                <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-base-price-text`}>
                                            <p className='m-1'>Kuvatakse {form.consumptionPackage.dynamicPackage ? 'fikseeritud tarbimise hinna' : 'baashinna'} all või asemel, kui hind puudub<br />(nt Tootmishind + marginaal)</p>
                                        </Tooltip>} placement={"right"}>
                                        <i className='icon__info icon__info--masked bg-dark ml-2 mb-n1'></i>
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                                value={form.consumptionPackage.basePriceTextEt}
                                disabled={true}
                                onChange={(e: any) => setForm({
                                    ...form, consumptionPackage: {
                                        ...form.consumptionPackage,
                                        basePriceTextEt: e.target.value
                                    }
                                })}
                                required={form.consumptionPackage.marketPricePackage}
                            />
                        </div>
                    ) : null
                }
            </div>
            {
                form.consumptionPackage.id === INDIVIDUAL_PACKAGE_VALUE ? (
                    <div className="row mt-3">
                        <div className="col-12 col-md-6">
                            <Form.Label>
                                {form.consumptionPackage.dynamicPackage ? 'Fikseeritud tarbimise hinna eestikeelne tekst' : 'Baashinna eestikeelne tekst'}
                                <OverlayTrigger delay={250}
                                    overlay={
                                        <Tooltip id={`tooltip-base-price-text`}>
                                            <p className='m-1'>Kuvatakse {form.consumptionPackage.dynamicPackage ? 'fikseeritud tarbimise hinna' : 'baashinna'} all või asemel, kui hind puudub<br />(nt Tootmishind + marginaal)</p>
                                        </Tooltip>} placement={"right"}>
                                        <i className='icon__info icon__info--masked bg-dark ml-2 mb-n1'></i>
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                                value={form.consumptionPackage.basePriceTextEt}
                                onChange={(e: any) => setForm({
                                    ...form, consumptionPackage: {
                                        ...form.consumptionPackage,
                                        basePriceTextEt: e.target.value
                                    }
                                })}
                                required={form.consumptionPackage.marketPricePackage}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Form.Label>
                                {form.consumptionPackage.dynamicPackage ? 'Fikseeritud tarbimise hinna inglisekeelne tekst' : 'Baashinna inglisekeelne tekst'}
                            </Form.Label>
                            <Form.Control
                                value={form.consumptionPackage.basePriceTextEn}
                                onChange={(e: any) => setForm({
                                    ...form, consumptionPackage: {
                                        ...form.consumptionPackage,
                                        basePriceTextEn: e.target.value
                                    }
                                })}
                            />
                        </div>
                    </div>
                ) : null
            }
        </>
    );
}