import React, {useState} from 'react';
import {Button, Form, Modal, Spinner } from "react-bootstrap";
import {ModalType} from "../../../common/common-models";
import { EditChargingPricingConfirmModal } from './EditChargingPricingConfirmModal';
import { ChargingPricing, ChargingPricingForm } from '../models';
import { ChargingPricingsService } from '../ChargingPricings.service';
import { isNotNullOrUndefined } from 'common/data-util';

interface EditChargingPricingModalProps {
    chargingPricing?: ChargingPricing
    modalType: ModalType;
    handleModalHide: () => void;
    loadChargingPricings: () => void;
}

export function EditChargingPricingModal(props: EditChargingPricingModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [validated, setValidated]: [boolean, any] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible]: [boolean, any] = useState(false);
    const [form, setForm]: [ChargingPricingForm, any] = useState({
        name : props.chargingPricing?.name,
        installationFeeEuros : props.chargingPricing?.installationFeeEuros,
        devicesRentalMonthlyFeeEuros : props.chargingPricing?.devicesRentalMonthlyFeeEuros,
        devicesFullServiceRentalMonthlyFeeEuros : props.chargingPricing?.devicesFullServiceRentalMonthlyFeeEuros,
        devicesCostEuros : props.chargingPricing?.devicesCostEuros,
        thirdPartyCompensationNetoFeeEuros : props.chargingPricing?.thirdPartyCompensationNetoFeeEuros,
        maintenanceMonthlyFeeEuros : props.chargingPricing?.maintenanceMonthlyFeeEuros,
        rfidPaymentCardMonthlyFeeEuros : props.chargingPricing?.rfidPaymentCardMonthlyFeeEuros,
        simCardMonthlyFeeEuros : props.chargingPricing?.simCardMonthlyFeeEuros,
        mediationAndBillingMonthlyFeePerNozzleEuros : props.chargingPricing?.mediationAndBillingMonthlyFeePerNozzleEuros,
        infrastructureUsageMonthlyFeePerNozzleEuros : props.chargingPricing?.infrastructureUsageMonthlyFeePerNozzleEuros,
        infrastructureUsageOneTimeFeePerNozzleEuros : props.chargingPricing?.infrastructureUsageOneTimeFeePerNozzleEuros,
        chargingServiceMonthlyFeeEuros : props.chargingPricing?.chargingServiceMonthlyFeeEuros,
        thirdPartyChargingMarginalCentsKwh : props.chargingPricing?.thirdPartyChargingMarginalCentsKwh,
        gridDayTariffCentsKwh : props.chargingPricing?.gridDayTariffCentsKwh,
        gridNightTariffCentsKwh : props.chargingPricing?.gridNightTariffCentsKwh,
    });

    const validateForm = () => {
        if(!isNotNullOrUndefined(form.installationFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.devicesRentalMonthlyFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.devicesFullServiceRentalMonthlyFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.devicesCostEuros)) return false;
        if(!isNotNullOrUndefined(form.thirdPartyCompensationNetoFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.maintenanceMonthlyFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.rfidPaymentCardMonthlyFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.simCardMonthlyFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.mediationAndBillingMonthlyFeePerNozzleEuros)) return false;
        if(!isNotNullOrUndefined(form.infrastructureUsageMonthlyFeePerNozzleEuros)) return false;
        if(!isNotNullOrUndefined(form.infrastructureUsageOneTimeFeePerNozzleEuros)) return false;
        if(!isNotNullOrUndefined(form.chargingServiceMonthlyFeeEuros)) return false;
        if(!isNotNullOrUndefined(form.thirdPartyChargingMarginalCentsKwh)) return false;
        if(!isNotNullOrUndefined(form.gridDayTariffCentsKwh)) return false;
        if(!isNotNullOrUndefined(form.gridNightTariffCentsKwh)) return false;
        
        return true;
    };

    const validateSubmit = () => {
        setValidated(false);
        const isValidForm = validateForm();
        setValidated(true);
        if (isValidForm) {
            setIsConfirmModalVisible(true);
        } else {
            setSaving(false);
        }
    };

    const handleSubmit = async () => {
        setSaving(true);
        setSavingError(false);

        try{
            if(props.modalType === ModalType.CREATE){
                await ChargingPricingsService.postChargingPricing(form);
            }else{
                await ChargingPricingsService.putChargingPricing(props.chargingPricing!.id, form);
            }

            setSaving(false);
            setTimeout(() => {
                props.handleModalHide();
                props.loadChargingPricings();
            }, 300);
        }catch(e){
            console.error('Saving failed', e);
            setSaving(false);
            setSavingError(true);
        }
    };

    return (
        <Modal
            show={true}
            size={"lg"}
            onHide={props.handleModalHide}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {
                        props.modalType === ModalType.CREATE &&
                        <span>Hinnakirja lisamine</span>
                    }
                    {
                        props.modalType === ModalType.EDIT &&
                        <span>Hinnakirja muutmine</span>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form 
                    noValidate 
                    validated={validated} onSubmit={(e: any) => e.preventDefault()}
                    id={"electricity-package-form"}
                    className={'mt-1 align-self-center m-0'}
                >
                    <Form.Group controlId={'form-charging-pricing-name'} className="row m-0">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Nimetus*</span>
                        </Form.Label>
                        <div className="col p-0">
                            <Form.Control
                                required
                                minLength={1}
                                value={form.name}
                                onChange={(e) => setForm({
                                    ...form,
                                    name: e.target.value
                                })
                                } 
                                disabled={saving}
                                type="text"
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-installation-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Seadme paigaldustasu (€)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.installationFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    installationFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-devices-rental-monthly-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Seadmete rendi kuutasu (€/kuu)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.devicesRentalMonthlyFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    devicesRentalMonthlyFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-devices-full-service-rental-monthly-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Seadmete täisteenusrendi kuutasu (€/kuu)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.devicesFullServiceRentalMonthlyFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    devicesFullServiceRentalMonthlyFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-installation-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Seadmete maksumus (€)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.devicesCostEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    devicesCostEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-maintenance-monthly-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Seadme hoolduse kuutasu (€/kuu)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.maintenanceMonthlyFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    maintenanceMonthlyFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-charging-service-monthly-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Laadimisteenuse kuutasu (€/kuu)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.chargingServiceMonthlyFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    chargingServiceMonthlyFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-charging-service-marginal'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Laadimisteenuse marginaal (s/kWh)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.thirdPartyChargingMarginalCentsKwh}
                                onChange={(e) => setForm({
                                    ...form,
                                    thirdPartyChargingMarginalCentsKwh: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-infra-usage-one-time-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Infrastruktuuri kasutamise ühekordne tasu laadija otsiku kohta (€)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.infrastructureUsageOneTimeFeePerNozzleEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    infrastructureUsageOneTimeFeePerNozzleEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-infra-usage-monthly-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Infrastruktuuri kasutamise kuutasu laadija otsiku koht (€/kuu)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.infrastructureUsageMonthlyFeePerNozzleEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    infrastructureUsageMonthlyFeePerNozzleEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-third-party-compensation'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Kolmandate isikute kompensatsioon (€)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.thirdPartyCompensationNetoFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    thirdPartyCompensationNetoFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-rfid-price'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">RFID-kiibiga maksekaardi hind (€/tk)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.rfidPaymentCardMonthlyFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    rfidPaymentCardMonthlyFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-sim-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">SIM-kaardi kuutasu SIM-kaardi kohta (€/tk)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control 
                                required
                                value={form.simCardMonthlyFeeEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    simCardMonthlyFeeEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-charging-pricing-mediation-billing-fee'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Arveldusteenuse ja arveldamise vahendamisteenuse kuutasu otsiku kohta (€/kuu)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control
                                required
                                value={form.mediationAndBillingMonthlyFeePerNozzleEuros}
                                onChange={(e) => setForm({
                                    ...form,
                                    mediationAndBillingMonthlyFeePerNozzleEuros: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group controlId={'form-charging-pricing-day-tariff'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Päevatariif (s/kWh)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control
                                required
                                value={form.gridDayTariffCentsKwh}
                                onChange={(e) => setForm({
                                    ...form,
                                    gridDayTariffCentsKwh: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group controlId={'form-charging-pricing-night-tariff'} className="row m-0 mt-3">
                        <Form.Label className={'col-5'}>
                            <span className="text-wrap">Öötariif (s/kWh)*</span>
                        </Form.Label>
                        <div className="col-3 p-0">
                            <Form.Control
                                required
                                value={form.gridNightTariffCentsKwh}
                                onChange={(e) => setForm({
                                    ...form,
                                    gridNightTariffCentsKwh: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                            />
                            <Form.Control.Feedback type="invalid">Kohustuslik väli</Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
            {
                validated && isConfirmModalVisible &&
                    <EditChargingPricingConfirmModal
                        name={form.name!}
                        handleConfirmSubmit={() => {
                            setIsConfirmModalVisible(false);
                            handleSubmit()
                        }}
                        handleConfirmReject={() => {
                            setSaving(false);
                            setIsConfirmModalVisible(false);
                        }}
                        modalType={props.modalType}/>
            }
        </Modal>
    );
}
