import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {EditElectricityPackageConfirmModal} from "./EditElectricityPackageConfirmModal";
import {
    IElectricityPackageModalProps,
    IElectricityPackageForm,
    ELECITRCITY_PACKAGE_BASE_FIXATION_TYPES,
    ElectricityPackageBaseFixationType
} from "../models";
import {ModalType} from "../../../common/common-models";
import {ElectricityPackagesService} from "../ElectricityPackages.service";
import { TermsAndConditionsService } from 'components/TermsAndConditions/TermsAndConditions.service';
import Select from 'react-select';
import { ElectricityAgreementsService } from 'components/ElectricityAgreements/ElectricityAgreements.service';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg';
import { getLastMonthWithFirstDay, getLastMonthWithLastDay, getMarketPricePackageProductDescription } from 'helpers';
import { isNotNullOrUndefined } from 'common/data-util';

export function EditElectricityPackageModal(props: IElectricityPackageModalProps) {
    const [saving, setSaving]: [boolean, any] = useState(false);
    const [savingError, setSavingError]: [boolean, any] = useState(false);
    const [validated, setValidated]: [boolean, any] = useState(false);
    const [isConfirmModalVisible, setIsConfirmModalVisible]: [boolean, any] = useState(false);
    const [form, setForm]: [IElectricityPackageForm, any] = useState({
        nameEt: props.editingElectricityPackage ? props.editingElectricityPackage.nameEt : "",
        nameEn: props.editingElectricityPackage ? props.editingElectricityPackage.nameEn : "",
        descriptionEt: props.editingElectricityPackage ? props.editingElectricityPackage.descriptionEt : "",
        descriptionEn: props.editingElectricityPackage ? props.editingElectricityPackage.descriptionEn : "",
        jointInvoicePackage: props.editingElectricityPackage ? props.editingElectricityPackage.jointInvoicePackage : false,
        marketPricePackage: props.editingElectricityPackage ? props.editingElectricityPackage.marketPricePackage : false,
        dynamicPackage: props.editingElectricityPackage ? props.editingElectricityPackage.dynamicPackage : false,
        baseFixationType: props.editingElectricityPackage ? props.editingElectricityPackage.baseFixationType : undefined,
        baseConsumptionProportion: props.editingElectricityPackage ? props.editingElectricityPackage.baseConsumptionProportion : undefined,
        pdfProductDescriptionOnInvoice: props.editingElectricityPackage ? props.editingElectricityPackage.pdfProductDescriptionOnInvoice : undefined,
        externalProductDescriptionOnInvoice: props.editingElectricityPackage ? props.editingElectricityPackage.externalProductDescriptionOnInvoice : undefined,
        basePrice: props.editingElectricityPackage ? props.editingElectricityPackage.basePrice : undefined,
        basePriceTextEt: props.editingElectricityPackage ? props.editingElectricityPackage.basePriceTextEt : '',
        basePriceTextEn: props.editingElectricityPackage ? props.editingElectricityPackage.basePriceTextEn : '',
        basePriceDescriptionEt: props.editingElectricityPackage ? props.editingElectricityPackage.basePriceDescriptionEt : "",
        basePriceDescriptionEn: props.editingElectricityPackage ? props.editingElectricityPackage.basePriceDescriptionEn : "",
        monthlyFee: props.editingElectricityPackage ? props.editingElectricityPackage.monthlyFee : undefined,
        contractPeriodId: props.editingElectricityPackage?.contractPeriodId,
        greenEnergy: props.editingElectricityPackage ? props.editingElectricityPackage.greenEnergy : false,
        active: props.editingElectricityPackage ? props.editingElectricityPackage.active : false,
        highlighted: props.editingElectricityPackage ? props.editingElectricityPackage.highlighted : false,
        orderNumber: props.editingElectricityPackage ? props.editingElectricityPackage.orderNumber : undefined,
        contractTitle: props.editingElectricityPackage ? props.editingElectricityPackage.contractTitle : "",
        pdfFooterDisclaimerPerson: props.editingElectricityPackage ? props.editingElectricityPackage.pdfFooterDisclaimerPerson : "",
        pdfFooterDisclaimerCompany: props.editingElectricityPackage ? props.editingElectricityPackage.pdfFooterDisclaimerCompany : "",
        termsAndConditions: props.editingElectricityPackage && props.editingElectricityPackage.terms ?
            { value: props.editingElectricityPackage.terms.id, label: props.editingElectricityPackage.terms.name } : undefined,
        showToPrivateCustomer : props.editingElectricityPackage ? props.editingElectricityPackage.showToPrivateCustomer : true,
        showToBusinessCustomer : props.editingElectricityPackage ? props.editingElectricityPackage.showToBusinessCustomer : true,
        marginals: props.editingElectricityPackage ? props.editingElectricityPackage.marginals : [{
            maxValue: undefined,
            price: undefined
        }],
        contractEndDate: props.editingElectricityPackage ? props.editingElectricityPackage.contractEndDate : ''
     });
    const [termsAndConditionsOptions, setTermsAndConditionsOptions] = useState<any[]>([]);
    const [electricityContractPeriods, setElectricityContractPeriods] = useState<any[]>([]);

    useEffect(() => {
        TermsAndConditionsService.getTermsAndConditions({})
            .then(res => setTermsAndConditionsOptions(
                res.content.map(c => ({ value: c.id, label: c.name }))));
        ElectricityAgreementsService.getElectricityContractPeriods()
            .then(res => setElectricityContractPeriods(
                res.map((c:any) => ({ value: c.id, label: c.name }))
            ));
    }, []);

    const handleFormMarginalsChange = (index: number, event: any) => {
        setForm({
            ...form,
            marginals: form.marginals.map((marginal, i) => i !== index ? marginal : {...marginal, [event.target.name]: event.target.value })
        });

    };

    const addFields = () => {
        setForm({
            ...form,
            marginals: [...form.marginals, {
                maxValue: undefined,
                price: undefined
            }]
        });    
    }

    const removeMarginal = (index: number) => {
            setForm({
            ...form,
            marginals: form.marginals?.filter((marginal, i) => i !== index) 
        });
    };

    const validateForm = () => {
        if (!form.nameEt || !form.nameEt.trim().length) return false;
        if (!form.descriptionEt || !form.descriptionEt.trim().length) return false;
        if (!form.contractTitle) return false;
        if(form.externalProductDescriptionOnInvoice && form.externalProductDescriptionOnInvoice?.length >= 150) return false;
        if (!form.termsAndConditions) return false;       
        if (form.marginals.some(marginal => marginal.price === null)) return false;
        if (form.orderNumber === undefined) return false;
        if(currentContractPeriod.label === 'Tähtajaline' && !form.contractEndDate) return false;
        if(form.dynamicPackage && (!form.baseFixationType || !form.baseConsumptionProportion || !isNotNullOrUndefined(form.basePrice))) return false;
        return true;      
    };

    const validateSubmit = () => {
        setValidated(false);
        const isValidForm = validateForm();
        setValidated(true);
        if (isValidForm) {
            setIsConfirmModalVisible(true);
        } else {
            setSaving(false);
        }
    };

    const handleSubmit = () => {
        setSaving(true);
        setSavingError(false);
        const request = {...form, termsAndConditionsId:form.termsAndConditions?.value}
        if (props.modalType === ModalType.CREATE) {
            ElectricityPackagesService.postElectricityPackage(request).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.handleModalHide();
                }, 300);
                props.loadElectricityPackages();
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        } else {
            if (!props.editingElectricityPackage || !props.editingElectricityPackage.id) {
                setSaving(false);
                console.error('Saving failed, no editable electricity package', props.editingElectricityPackage);
                return;
            }

            ElectricityPackagesService.putElectricityPackage(props.editingElectricityPackage.id, request).then(() => {
                setSaving(false);
                setTimeout(() => {
                    props.handleModalHide();
                }, 300);
                props.loadElectricityPackages();
            }).catch((reason: any) => {
                console.error('Saving failed', reason);
                setSaving(false);
                setSavingError(true);
            });
        }
    };

    const currentContractPeriod = electricityContractPeriods.find(p => p.value === form.contractPeriodId) || null;
    const currentBaseFixationType = ELECITRCITY_PACKAGE_BASE_FIXATION_TYPES.find(p => p.value === form.baseFixationType) || null;

    return (
        <Modal
            show={true}
            size={"xl"}
            onHide={props.handleModalHide}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    {
                        props.modalType === ModalType.CREATE &&
                        <span>Elektripaketi lisamine</span>
                    }
                    {
                        props.modalType === ModalType.EDIT &&
                        <span>Elektripaketi muutmine</span>
                    }
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={(e: any) => e.preventDefault()}
                      id={"electricity-package-form"}
                      className={'mt-1 align-self-center m-0'}>
                    <Form.Group controlId={'form-electricity-package-name'} className="row m-0">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Elektripaketi eestikeelne nimetus*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            className="col-12 col-lg-8"
                            minLength={1}
                            value={form.nameEt}
                            onChange={(e) => setForm({
                                ...form,
                                nameEt: e.target.value
                            })
                            } 
                            disabled={saving}
                            type="text"/>
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-name-in-english'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Elektripaketi inglisekeelne nimetus</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            minLength={1}
                            value={form.nameEn}
                            onChange={(e) => setForm({
                                ...form,
                                nameEn: e.target.value
                            })
                            } 
                            disabled={saving}
                            type="text"/>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-description'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Elektripaketi eestikeelne kirjeldus*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            className="col-12 col-lg-8"
                            value={form.descriptionEt}
                            onChange={(e) => setForm({
                                ...form,
                                descriptionEt: e.target.value
                            })
                            }
                            disabled={saving}
                            as="textarea"
                            rows={5}/>
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-description'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Elektripaketi inglisekeelne kirjeldus</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.descriptionEn}
                            onChange={(e) => setForm({
                                ...form,
                                descriptionEn: e.target.value
                            })
                            }
                            disabled={saving}
                            as="textarea"
                            rows={5}/>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-is-market-price-package'} className='row m-0 mt-3'>
                        <Form.Label className={'mb-0 align-self-center text-start col-3'}>
                            <span className='text-nowrap'>Börsipakett</span>
                        </Form.Label>
                        <div className='col-1 pl-0'>
                            <Form.Check
                                className='form-check-input-no-label'
                                id='form-electricity-package-market-price-package'
                                label=''
                                checked={form.marketPricePackage}
                                onChange={(e: any) => {
                                    if(e.target.checked){
                                        setForm({
                                            ...form,
                                            marketPricePackage: true,
                                            dynamicPackage: false,
                                            basePrice: undefined
                                        })
                                    }else{
                                        setForm({
                                            ...form,
                                            marketPricePackage: false
                                        })
                                    }
                                }}
                                disabled={saving}
                                type='checkbox'
                            />
                        </div>
                    </Form.Group>
                    <Form.Group controlId={'form-electricity-package-is-market-price-package'} className='row m-0 mt-3'>
                        <Form.Label className={'mb-0 align-self-center text-start col-3'}>
                            <span className='text-nowrap'>Dünaamiline pakett</span>
                        </Form.Label>
                        <div className='col-1 pl-0'>
                            <Form.Check
                                className='form-check-input-no-label'
                                id='form-electricity-package-market-price-package'
                                label=''
                                checked={form.dynamicPackage}
                                onChange={(e: any) => {
                                    if(e.target.checked){
                                        setForm({
                                            ...form,
                                            dynamicPackage: true,
                                            marketPricePackage: false
                                        })
                                    }else{
                                        setForm({
                                            ...form,
                                            dynamicPackage: false,
                                            baseFixationType: undefined,
                                            baseConsumptionProportion: undefined,
                                        })
                                    }
                                }}
                                disabled={saving}
                                type='checkbox'
                            />
                        </div>
                    </Form.Group>
                    {
                        form.dynamicPackage && (
                            <>
                                <Form.Group controlId={'form-electricity-package-terms'} className="row m-0 mt-3">
                                    <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                                        <span className="text-nowrap">Fikseerimise tüüp*</span>
                                    </Form.Label>
                                    <Select
                                            className={'w-100 col-12 col-lg-8 px-0 form-control form-select' + (!form.baseFixationType && validated ? ' is-invalid' : '')} 
                                            options={ELECITRCITY_PACKAGE_BASE_FIXATION_TYPES}
                                            value={currentBaseFixationType}
                                            onChange={(val: any) => setForm({
                                                ...form,
                                                baseFixationType: val.value
                                            })}
                                            placeholder='Vali fikseerimise tüüp'
                                            required
                                        />
                                    <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>    
                                </Form.Group>
                                <Form.Group controlId={'form-electricity-package-base-consumption-proportion'} className="row m-0 mt-3">
                                    <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                                        <span className="text-nowrap">Fikseeritud tarbimise osakaal {form.baseFixationType === ElectricityPackageBaseFixationType.PERCENTAGE ? "(%)" : "(MWh)"}</span>
                                    </Form.Label>
                                    <Form.Control 
                                        className={'col-12 col-lg-8'} 
                                        required
                                        value={form.baseConsumptionProportion}
                                        onChange={(e) => setForm({
                                            ...form,
                                            baseConsumptionProportion: e.target.value
                                        })
                                        }
                                        disabled={saving}
                                        type="number"
                                    />
                                    <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>    
                                </Form.Group>
                            </>
                        )
                    }
                    {!form.marketPricePackage ?   
                        <Form.Group controlId={'form-electricity-package-base-price'} className="row m-0 mt-3">
                            <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                                <span className="text-nowrap">{form.dynamicPackage ? 'Fikseeritud tarbimise hind KM-ta (s/kWh)' : 'Baashind KM-ta (s/kWh)'}</span>
                            </Form.Label>
                            <Form.Control 
                                className="col-12 col-lg-8"
                                value={form.basePrice}
                                onChange={(e) => setForm({
                                    ...form,
                                    basePrice: e.target.value
                                })
                                }
                                disabled={saving}
                                type="number"
                                required={form.dynamicPackage}
                            />
                            {form.dynamicPackage && ( <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback> )}
                        </Form.Group>
                    : null}
                    <Form.Group controlId={'form-electricity-package-base-price-description'} className="row m-0 mt-3">
                        <Form.Label className={'col-12 col-lg-3 d-flex flex-column align-self-center text-start mb-0'}>
                            <span className="text-wrap">{form.dynamicPackage ? 'Fikseeritud tarbimise hinna eestikeelne tekst' : 'Baashinna eestikeelne tekst'}
                            <OverlayTrigger delay={250}
                                overlay={
                                    <Tooltip id={`tooltip-base-price-text`}>
                                        <p className='m-1'>Kuvatakse {form.dynamicPackage ? 'fikseeritud tarbimise hinna' : 'baashinna'} all või asemel, kui hind puudub<br />(nt Tootmishind + marginaal)</p>
                                    </Tooltip>} placement={"right"}>
                                    <i className='icon__info icon__info--masked bg-dark ml-2 mb-n1'></i>
                            </OverlayTrigger>
                            </span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.basePriceTextEt}
                            onChange={(e) => setForm({
                                ...form,
                                basePriceTextEt: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"/>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-base-price-description'} className="row m-0 mt-3">
                        <Form.Label className={'col-12 col-lg-3 d-flex flex-column align-self-center text-start mb-0'}>
                            <span className="text-wrap">{form.dynamicPackage ? 'Fikseeritud tarbimise hinna inglisekeelne tekst' : 'Baashinna inglisekeelne tekst'}</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.basePriceTextEn}
                            onChange={(e) => setForm({
                                ...form,
                                basePriceTextEn: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"/>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-base-price-description'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-wrap">{form.dynamicPackage ? 'Fikseeritud tarbimise hinna eestikeelne kirjeldus' : 'Baashinna eestikeelne kirjeldus'}
                            <OverlayTrigger delay={250}
                                overlay={
                                    <Tooltip id={`tooltip-base-price-description`}>
                                        <p className='m-1'>Näiteks "Igakuiselt fikseeritud kWh hind"</p>
                                    </Tooltip>} placement={"right"}>
                                    <i className='icon__info icon__info--masked bg-dark ml-2 mb-n1'></i>
                            </OverlayTrigger>                            
                            </span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.basePriceDescriptionEt}
                            onChange={(e) => setForm({
                                ...form,
                                basePriceDescriptionEt: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"/>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-base-price-description'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-wrap">{form.dynamicPackage ? 'Fikseeritud tarbimise hinna inglisekeelne kirjeldus' : 'Baashinna inglisekeelne kirjeldus'}</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.basePriceDescriptionEn}
                            onChange={(e) => setForm({
                                ...form,
                                basePriceDescriptionEn: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"/>
                    </Form.Group>

                    <Form.Group 
                        controlId={'form-electricity-package-monthly-fee'} 
                        className="row m-0 mt-3"
                    >
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Kuutasu KM-ta (€)</span>
                        </Form.Label>
                        <Form.Control 
                            className="col-12 col-lg-8"
                            value={form.monthlyFee}
                            onChange={(e) => setForm({
                                ...form,
                                monthlyFee: e.target.value
                            })
                            }
                            disabled={saving}
                            type="number"
                        />
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-is-joint-invoice-package'} className='row m-0 mt-3'>
                        <Form.Label className={'mb-0 align-self-center text-start col-3'}>
                            <span className='text-nowrap'>Ühisarve</span>
                        </Form.Label>
                        <div className='col-1 pl-0'>
                            <Form.Check
                                className='form-check-input-no-label'
                                id='form-electricity-package-join-invoice-package'
                                label=''
                                checked={form.jointInvoicePackage}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    jointInvoicePackage: e.target.checked
                                })}
                                disabled={saving}
                                type='checkbox'
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-contract-period'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Lepinguperiood*</span>
                        </Form.Label>
                        <Select
                                options={electricityContractPeriods}
                                value={currentContractPeriod}
                                onChange={(val: any) => setForm({
                                    ...form,
                                    contractPeriodId: val.value
                                })}
                                isDisabled={saving}
                                className={'w-100 col-12 col-lg-8 px-0 form-control form-select' + (!currentContractPeriod && validated ? ' is-invalid' : '')} 
                                placeholder='Vali lepingu periood'
                            />
                    </Form.Group>
                    {
                        currentContractPeriod?.label === "Tähtajaline" ? (
                            <Form.Group controlId={'form-electricity-package-contract-end'} className="row m-0 mt-3">
                                <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                                    <span className="text-nowrap">Lepingu lõpp*</span>
                                </Form.Label>
                                <DatePicker
                                    className={'w-100 col-12 col-lg-8 px-0 ' + (form.contractEndDate === undefined && validated ? ' invalid is-invalid' : '')}
                                    value={form.contractEndDate ? new Date(form.contractEndDate) : undefined}
                                    locale='et'
                                    clearIcon={null}
                                    calendarIcon={<CalendarIcon />}
                                    format='dd.MM.yyyy'
                                    minDate={moment().add(1, 'M').toDate()}
                                    onChange={(event: any) => {
                                        setForm({
                                            ...form, 
                                            contractEndDate: event ? new Date(event) : undefined
                                        })
                                    }}
                                />
                            </Form.Group>
                        ) : null
                    }
                    <Form.Group controlId={'form-electricity-package-contract-title'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Lepingu pealkiri*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            className="col-12 col-lg-8"
                            value={form.contractTitle}
                            onChange={(e) => setForm({
                                ...form,
                                contractTitle: e.target.value
                            })
                            }
                            disabled={saving}
                            type="text"
                        />
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-footer-person'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Eraisiku lepingu eritingimused</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.pdfFooterDisclaimerPerson}
                            onChange={(e) => setForm({
                                ...form,
                                pdfFooterDisclaimerPerson: e.target.value
                            })
                            }
                            disabled={saving}
                            as="textarea"
                            rows={5}/>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-footer-company'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Ettevõtte lepingu eritingimused</span>
                        </Form.Label>
                        <Form.Control
                            className="col-12 col-lg-8"
                            value={form.pdfFooterDisclaimerCompany}
                            onChange={(e) => setForm({
                                ...form,
                                pdfFooterDisclaimerCompany: e.target.value
                            })
                            }
                            disabled={saving}
                            as="textarea"
                            rows={5}/>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-terms'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-12 col-lg-3'}>
                            <span className="text-nowrap">Tüüptingimused*</span>
                        </Form.Label>
                        <Select
                                options={termsAndConditionsOptions}
                                value={form.termsAndConditions}
                                onChange={(val: any) => setForm({
                                    ...form,
                                    termsAndConditions: val
                                })}
                                className={'w-100 col-12 col-lg-8 px-0 form-control form-select' + (!form.termsAndConditions && validated ? ' is-invalid' : '')} 
                                placeholder='Vali elektripaketi tüüptingimus'
                                required
                            />
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>    
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-marginals'} className="row m-0 mt-3">
                            <Form.Label className={'mb-0 align-self-start text-start col-12 col-lg-3'}>
                                <span className='text-nowrap'>{form.dynamicPackage ? 'Börsihinnaga tarbimise marginaalid' : 'Marginaalid'}</span>
                            </Form.Label>

                            <div className = 'col-12 col-lg-9'>
                                <div className='row'>
                                    <label className='col-4 pl-0'>Maksimum tarbimine kWh</label>
                                    <label className='col-4 pl-2'>Hind KM-ta (s/kWh)</label>
                                </div>
                                {
                                form.marginals?.map((marginal, index)=> {
                                return (
                                    <div
                                        className={'row mb-2'}
                                        key = {index}>

                                        <input
                                            className = "col-4 form-control d-inline-block"
                                            name = "maxValue"
                                            placeholder = 'Maksimum'
                                            value = {marginal.maxValue}
                                            step={0.001}
                                            onChange = {event => handleFormMarginalsChange(index, event)} 
                                            type = "number"
                                        />

                                        <input
                                            className = "col-4 form-control d-inline-block mx-2"
                                            name = "price"
                                            placeholder = 'Hind'
                                            value = {marginal.price}
                                            step={0.001}
                                            onChange = {event => handleFormMarginalsChange(index, event)} 
                                            type = "number"
                                            required
                                        />

                                        <div className = 'col-1 d-flex align-items-center'>
                                            {form.marginals.length > 1 && 
                                                <button
                                                        type = 'button'
                                                        className = "d-flex align-items-center border-color-background border-radius-circle btn-outline-icon btn btn-outline-danger btn-sm"
                                                        onClick={() => removeMarginal(index)}>
                                                <i className="icon--16 my-2 icon__delete--outline-mask"></i>
                                                </button>
                                            }

                                        </div>
                                    </div>
                                )                            
                            })}
                            <div className='row'>
                                <div className='col-12 p-0'>
                                    <button
                                            type = 'button'
                                            className = 'd-flex align-items-center btn btn-link pl-0'
                                            onClick={addFields}>
                                        <i className="icon--16 icon__add--primary d-flex mr-1"></i>
                                        <span>Lisa uus</span>
                                    </button>
                                </div>
                            </div>
                        </div>    
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-characterisitcs'} className="row m-0 my-3 py-3 border-bottom">
                        <Form.Label className={'mb-0 align-self-center text-start col-3'}>
                            <span className="text-nowrap">Omadused</span>
                        </Form.Label>
                        <div className="col-4 pl-0">
                            <Form.Check 
                                className="form-check-label-black"
                                id="form-electricity-package-green-energy"
                                label="Roheteenus"
                                checked={form.greenEnergy}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    greenEnergy: e.target.checked
                                })}
                                disabled={saving}
                                type="checkbox"/>
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-settings'} className="row m-0 my-3">
                        <Form.Label className={'col-3'}>
                            <span className="text-nowrap">Sätted</span>
                        </Form.Label>
                        <div className="col p-0">
                            <Form.Check 
                                className="form-check-label-black"
                                id="form-electricity-package-active"
                                label="Aktiivne"
                                checked={form.active}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    active: e.target.checked
                                })}
                                disabled={saving}
                                type="checkbox"
                            />
                            <Form.Check 
                                className="form-check-label-black mt-2"
                                id="form-electricity-package-highlighted"
                                label="Esile tõstetud"
                                checked={form.highlighted}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    highlighted: e.target.checked
                                })}
                                disabled={saving}
                                type="checkbox"
                            />
                            <Form.Check 
                                className="form-check-label-black mt-2"
                                id="form-electricity-package-show-to-private-customers"
                                label="Pakett kuvatakse eraklientidele"
                                checked={form.showToPrivateCustomer}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    showToPrivateCustomer: e.target.checked
                                })}
                                disabled={saving}
                                type="checkbox"
                            />
                            <Form.Check 
                                className="form-check-label-black mt-2"
                                id="form-electricity-package-show-to-business-customers"
                                label="Pakett kuvatakse äriklientidele"
                                checked={form.showToBusinessCustomer}
                                onChange={(e: any) => setForm({
                                    ...form,
                                    showToBusinessCustomer: e.target.checked
                                })}
                                disabled={saving}
                                type="checkbox"
                            />
                        </div>
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-oreder-number'} className="row m-0 mt-3">
                        <Form.Label className={'mb-0 align-self-center text-start col-6 col-lg-3'}>
                            <span className="text-nowrap">Järjekorranumber*</span>
                        </Form.Label>
                        <Form.Control
                            required
                            className="col-6 col-lg-2"
                            value={form.orderNumber}
                            onChange={(e) => setForm({
                                ...form,
                                orderNumber: e.target.value
                            })
                            }
                            disabled={saving}
                            type="number"
                        />
                        <Form.Control.Feedback className={'col-12 col-lg-8 offset-lg-3 pl-0'} type="invalid">Kohustuslik väli</Form.Control.Feedback>    
                    </Form.Group>

                    <Form.Group controlId={'form-electricity-package-pdf-product-description'} className="row m-0 my-3">
                        <Form.Label className={'col-3'}>
                            <span className="text-nowrap">Arve toote kirjeldus PDF-il</span>
                        </Form.Label>
                        <div className="p-0 col-6">
                            <Form.Control
                                className='col-6 mb-1'
                                value={form.pdfProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        pdfProductDescriptionOnInvoice: e.target.value
                                    })
                                }
                                disabled={saving}
                                type="text"
                            />
                            <div className='col-12 p-0 text-break text-gray-700'>{form.pdfProductDescriptionOnInvoice ?? (form.marketPricePackage ? getMarketPricePackageProductDescription(form.nameEt) : "Elektrienergia universaalteenuse hinnaga")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) mõõtepunktis <b>X</b> aadressiga <b>X</b></div>
                        </div>
                    </Form.Group>
                    <Form.Group controlId={'form-electricity-package-merit-product-description'} className="row m-0 my-3">
                        <Form.Label className={'col-3'}>
                            <span className="text-nowrap">Arve toote kirjeldus meritis</span>
                        </Form.Label>
                        <div className="p-0 col-6">
                            <Form.Control
                                className='col-6 mb-1'
                                value={form.externalProductDescriptionOnInvoice}
                                onChange={(e) => setForm({
                                        ...form,
                                        externalProductDescriptionOnInvoice: e.target.value
                                    })
                                }
                                disabled={saving}
                                maxLength={150}
                                type="text"
                                isInvalid={validated && (form.externalProductDescriptionOnInvoice ? form.externalProductDescriptionOnInvoice.length >= 150 : false)}
                            />
                            <Form.Control.Feedback type="invalid">Maksimaalne pikkus on 150 tähemärki</Form.Control.Feedback>    
                            <div className='col-12 p-0 text-break text-gray-700'>{form.externalProductDescriptionOnInvoice ?? (form.marketPricePackage ? getMarketPricePackageProductDescription(form.nameEt) : "Elektri universaalteenus")} ({getLastMonthWithFirstDay()} - {getLastMonthWithLastDay()}) - (<b>X</b>) <b>X</b></div>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer className="d-flex flex-row align-items-end justify-content-around flex-wrap mb-1">
                <Button variant="info"
                        onClick={() => props.handleModalHide()}
                        type="button"
                        className={"d-flex align-items-center"}>
                    <span className='px-2'>Tühista</span>
                </Button>
                <Button variant="primary" onClick={validateSubmit} type="button" disabled={saving}
                        className="d-flex align-items-center">
                    {
                        !saving &&
                        <span className='px-2'>Salvesta</span>
                    }
                    {
                        saving &&
                        <>
                            <span className='px-2'>Salvestab…</span>
                            <Spinner size={"sm"} animation={"border"}/>
                        </>
                    }
                </Button>

            </Modal.Footer>
            {
                !saving && savingError &&
                <span className="d-flex justify-content-end text-danger px-2 pb-2">
                    Viga salvestamisel, proovi mõne hetke pärast uuesti.
                </span>
            }
            {
                validated && isConfirmModalVisible &&
                    <EditElectricityPackageConfirmModal
                        electricityPackageName={form.nameEt}
                        handleConfirmSubmit={() => {
                            setIsConfirmModalVisible(false);
                            handleSubmit()
                        }}
                        handleConfirmReject={() => {
                            setSaving(false);
                            setIsConfirmModalVisible(false);
                        }}
                        modalType={props.modalType}/>
            }
        </Modal>
    );
}
