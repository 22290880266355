/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, FormGroup } from "react-bootstrap";
import { ElectricityAgreementForm } from "../../models";
import { BANK_ACCOUNT_NUMBER_REGEX_PATTERN } from "common/constants/regex";
import { isValidCommaSeperatedEmail } from "helpers/validator";

interface NewElectricityAgreementBillingFormProps {
    form: ElectricityAgreementForm;
    setForm: (form: ElectricityAgreementForm) => void;
}

export default function NewElectricityAgreementBillingForm(
    { form, setForm } : NewElectricityAgreementBillingFormProps
) {

    return (
        <div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Label>Arve saaja e-post</Form.Label>
                    <Form.Control
                        value={form?.invoiceRecipientEmail ?? ''}
                        onChange={(e: any) => setForm({
                            ...form, 
                            invoiceRecipientEmail: e.target.value
                        })}
                        placeholder={form.email}
                        type="text"
                        isInvalid={form.validated && (!!form.invoiceRecipientEmail && !isValidCommaSeperatedEmail(form.invoiceRecipientEmail))}
                    />
                    <Form.Control.Feedback className={'text-right'} type="invalid">Vigane e-post!</Form.Control.Feedback>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-6">
                    <Form.Check
                        id='check-e-invoice'
                        custom
                        type="checkbox"
                        className='checkbox-large'
                        label='E-arve'
                        onChange={() => setForm({ ...form, eInvoice: !form.eInvoice })}
                        checked={form.eInvoice}
                    />
                </div>
            </div>
            {form.eInvoice && (
                <div className="row mt-2">
                    <FormGroup className="col-12 col-md-6">
                        <Form.Label className={'mb-0 align-self-start m-1 text-center'}>
                            <span className="text-nowrap">Pangakonto number*</span>
                        </Form.Label>
                        <Form.Control
                            value={form.bankAccountNumber}
                            type="text"
                            onChange={(val: any) => setForm({
                                ...form,
                                bankAccountNumber: val.target.value
                            })}
                            pattern={BANK_ACCOUNT_NUMBER_REGEX_PATTERN}
                            required
                            placeholder="EE909900123456789012"
                        />
                        <Form.Control.Feedback className={'text-right'} type="invalid">Pangakonto number ei ole valiidne</Form.Control.Feedback>
                    </FormGroup>
                </div>
            )}
        </div>
    );
}