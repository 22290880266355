import React from "react";


export const FormContentBlock = ({ title, subtitle, children }: { title: string, subtitle?: string, children?: any }) => {

    return (
        <div className="row mt-3 mt-lg-5">
            <div className="col-12 col-lg-3 p-4">
                <h2>{ title }</h2>
                {
                    subtitle ?
                    <p>
                        { subtitle }
                    </p> : null
                }

            </div>
            <div className="col-12 col-lg-9 bg-white rounded p-4">
                { children }
            </div>
        </div>
    )
}